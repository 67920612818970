import React, { useEffect, useState, useRef } from "react";
import { BRICK_COLOURS } from "../common/constants";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

const ColorPicker = ({ setDonation, goToNextStep, donation, addTransitions }) => {
  const colorPicker = useRef(null);

  useGSAP(() => {
    const introAnimation = gsap.to(colorPicker.current, { opacity: 1, duration: 0.5 });
    addTransitions(introAnimation, "color-intro", false); // intro animation

    const exitAnimation = gsap.to(colorPicker.current, { opacity: 0, duration: 0.5 });
    addTransitions(exitAnimation, "color-exit", true); // exit animation
  }, [addTransitions]);

  function handleBrickSelect(e) {
    setDonation({ ...donation, brick_colour: e.target.value });
  }
  return (
    <form id="color-picker" className="start-hidden" ref={colorPicker} onSubmit={goToNextStep}>
      <fieldset className="card-grid">
        <legend className="visually-hidden">Select a brick colour</legend>
        {BRICK_COLOURS.map(({ label, value }) => (
          <label key={value} className="card card--radio text-size-medium">
            <input type="radio" name="color" value={value} checked={donation.brick_colour === value} onChange={handleBrickSelect} />
            {label}
            <div className={`brick brick--${value}`}>
              <span className="brick-img"></span>
            </div>
          </label>
        ))}
      </fieldset>
      <button className="button" disabled={donation.brick_colour === null}>
        Continue
      </button>
    </form>
  );
};

export { ColorPicker };
