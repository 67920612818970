import React, { useState, useEffect, useRef, useCallback } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation, useMatch, useRoutes } from "react-router-dom";
import { Canvas } from "@react-three/fiber";
import { Perf } from "r3f-perf";
import { View, OrbitControls, OrthographicCamera, PerspectiveCamera } from "@react-three/drei";
import { GUI } from "lil-gui";
import { Vector3 } from "three";

import { Building } from "./Building";
import { Fireworks } from "./Fireworks";
import { ViewEcard } from "./ViewEcard";
import { Donors } from "./Donors";
import { Gallery } from "./Gallery";
import { StepCounter } from "./StepCounter";
import { Intro, ZonePicker, ColorPicker, Finish, EcardCustomizer, ShareEcard } from "./Steps/index";
import Donation from "./types/Donation";
import { BASE_URL, ZONES, DEFAULT_CAMERA_SETTINGS, DEFAULT_DONATION } from "./common/constants";
import Sky from "./Sky";
import { Brick } from "./Brick";

import Sparkles from "./Sparkles";

import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollToPlugin } from "gsap/all";

const App = () => {
  const [donation, setDonation] = useState<Donation>(
    localStorage.getItem("donation") ? JSON.parse(localStorage.getItem("donation")) : DEFAULT_DONATION
  );
  const [donors, setDonors] = useState<Donation[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const container = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isTablet, setIsTablet] = useState<boolean>(false);
  const [isSmallDesktop, setIsSmallDesktop] = useState<boolean>(false);
  const buildingRef = useRef<HTMLDivElement>(null);
  const [isLastPage, setIsLastPage] = useState<boolean>(false);
  const [isEcardView, setIsEcardView] = useState<boolean>(false);

  const [cameraSettings, setCameraSettings] = useState(DEFAULT_CAMERA_SETTINGS);
  const BUILDING_SCALE = 0.05;
  const gui = useRef();
  useEffect(() => {
    gui.current = new GUI();
    return () => {
      gui.current.destroy();
    };
  }, []);

  useEffect(() => {
    if (window.location.search.includes("donation")) {
      fetch(BASE_URL + "/donations/" + window.location.search.split("=")[1])
        .then((response) => response.json())
        .then((json) => setDonation(json));
    }
  }, []);

  // animations
  gsap.registerPlugin(ScrollToPlugin, useGSAP);
  const [tl, setTL] = useState<gsap.core.Timeline>();

  const { contextSafe } = useGSAP(() => {
    const tl = gsap.timeline({ paused: true });
    if (activeStep === 0) {
      tl.to(".sky", { duration: 0.5, opacity: 0 }, "start");
      tl.to(window, { duration: 0.2, scrollTo: { y: "#building", offsetY: isMobile ? 16 : isTablet ? 48 : 70 } }, "<");
      tl.to("#campaign-intro", { duration: 0.5, opacity: 0 }, "<");
    }
    tl.addLabel("exit1");
    setTL(tl);
  }, []);

  const addTransitions = useCallback(
    (animation, name, isExit, position) => {
      if (!tl) return;
      tl && tl.add(animation, ">").addLabel(name);
      if (isExit) {
        const labelNames = Object.keys(tl?.labels);
        // console.log("playing enter animation: ", name);
        // tween to intro animation (second last)
        tl?.tweenTo(labelNames[labelNames.length - 2]).then(() => {
          // console.log("after animating, current playhead", tl?.currentLabel(), tl?.time());
        });
      }
      setTL(tl);
    },
    [tl]
  );

  const goToNextStep = (e) => {
    if (e) {
      e.preventDefault();
    }

    const nextAnimation = tl?.nextLabel();
    // console.log("playing exit animation: ", nextAnimation);
    // console.log("before animating, current playhead", tl?.currentLabel(), tl?.time());

    tl?.tweenTo(nextAnimation).then(() => {
      if (activeStep < steps.length - 1) {
        navigate(steps[activeStep + 1].slug);
        setActiveStep(activeStep + 1);
        // console.log("after animating, current playhead", tl?.currentLabel(), tl?.time());
      }
    });
  };

  const resetDonation = () => {
    localStorage.removeItem("donation");
    setDonation(DEFAULT_DONATION);
    console.log(donation, localStorage.getItem("donation"));
  };

  const goToStep = (step: number) => {
    const nextAnimation = tl?.nextLabel();

    tl?.tweenTo(nextAnimation).then(() => {
      if (step === 1) {
        resetDonation();
      }
      if (step >= 0 && step < steps.length) {
        navigate(steps[step].slug);
        setActiveStep(step);
      }
    });
  };

  const steps = [
    {
      title: "Donate",
      stepName: "donate",
      h1Class: "visually-hidden",
      slug: "/",
      showSky: true,
      showBuilding: true,
      buildingScale: BUILDING_SCALE,
      buildingRotation: [0, 0, 0],
      buildingAnimate: true,
      content: <Intro goToNextStep={goToNextStep} resetDonation={resetDonation} />,
    },
    {
      title: "Choose an area for your brick",
      stepName: "zone-picker",
      h1Class: "step-heading",
      slug: "/choose-a-wall",
      showBuilding: true,
      buildingScale: BUILDING_SCALE,
      content: <ZonePicker donation={donation} setDonation={setDonation} goToNextStep={goToNextStep} addTransitions={addTransitions} />,
    },
    {
      title: "Choose a Colour",
      stepName: "color-picker",
      h1Class: "step-heading",
      slug: "/choose-a-colour",
      showBuilding: true,
      showBrick: true,
      brickSpinning: true,
      buildingScale: donation.brick_colour ? 0 : BUILDING_SCALE,
      buildingRotation: [0, 0, 0],
      buildingAnimate: false,
      content: <ColorPicker donation={donation} setDonation={setDonation} goToNextStep={goToNextStep} addTransitions={addTransitions} />,
    },
    {
      title: "Finish Your Brick",
      stepName: "payment",
      h1Class: "step-heading",
      showBrick: false,
      slug: "/payment",
      showSky: false,
      content: <Finish donation={donation} setDonation={setDonation} goToNextStep={goToNextStep} addTransitions={addTransitions} />,
    },
    {
      title: "Thank you for your support!",
      stepName: "thank",
      h1Class: "heading",
      slug: "/share-your-brick",
      showBrick: false,
      showBuilding: true,
      buildingScale: BUILDING_SCALE,
      buildingAnimate: true,
      content: <EcardCustomizer donation={donation} setDonation={setDonation} goToNextStep={goToNextStep} addTransitions={addTransitions} />,
    },
    {
      title: "",
      stepName: "done",
      slug: "/done",
      showSky: true,
      content: <ShareEcard donation={donation} goToStep={goToStep} addTransitions={addTransitions} />,
    },
  ];

  const [activeStep, setActiveStep] = useState<number>(
    Math.max(
      steps.findIndex((step) => step.slug === location.pathname),
      0
    )
  );

  useEffect(() => {
    const currentStep = steps.findIndex((step) => {
      return step.slug === location.pathname;
    });
    setActiveStep(Math.max(currentStep, 0));
  }, [location]);

  const currentStep = steps[activeStep].stepName;

  useEffect(() => {
    fetch(BASE_URL + "/donations?idx=1&size=" + (isMobile ? "5" : "14"))
      .then((response) => response.json())
      .then((json) => {
        setDonors(json);

        if (json.length < (isMobile ? 5 : 14)) {
          setIsLastPage(true);
        }
      });
  }, [isMobile]);

  useEffect(() => {
    localStorage.setItem("donation", JSON.stringify(donation));
  }, [donation]);

  const [useIntermediate, setUseIntermediate] = useState(false);

  useEffect(() => {
    if ((donation.brick_zone && currentStep == "zone-picker") || (currentStep == "color-picker" && !donation.brick_colour)) {
      const { position, rotation, zoom, zoomMobile, target } = ZONES.find((zone) => zone.value === donation.brick_zone);

      if (position && zoom && target) {
        if ((donation.brick_zone === "south" || donation.brick_zone === "north") && useIntermediate) {
          const intermediatePosition = new Vector3(
            (cameraSettings.target.x + target.x) / 2 + 1.3, // tweak offset here
            (cameraSettings.target.y + target.y) / 2,
            (cameraSettings.target.z + target.z) / 2
          );

          setCameraSettings({
            ...cameraSettings,
            position: intermediatePosition,
            // intermediateTarget,
            target,
            zoom: 0.3,
          });
          setTimeout(() => {
            setCameraSettings({
              ...cameraSettings,
              position,
              target,
              zoom: isMobile ? zoomMobile : zoom,
            });
          }, 400);
        } else {
          setCameraSettings({
            ...cameraSettings,
            position,
            target,
            zoom: isMobile ? zoomMobile : zoom,
          });
        }
      }
    } else if (currentStep == "thank" || currentStep == "fireworks") {
      setCameraSettings({
        ...cameraSettings,
        position: DEFAULT_CAMERA_SETTINGS.position,
        target: DEFAULT_CAMERA_SETTINGS.target,
        zoom: 2.5,
      });
    } else {
      setCameraSettings({
        ...cameraSettings,
        position: DEFAULT_CAMERA_SETTINGS.position,
        target: DEFAULT_CAMERA_SETTINGS.target,
        zoom: DEFAULT_CAMERA_SETTINGS.zoom,
      });
    }

    if (donation.brick_zone === "south" || donation.brick_zone === "north") {
      setUseIntermediate(true);
    }
  }, [donation, activeStep, isMobile, isTablet, isSmallDesktop]);

  useEffect(() => {
    // get scrollbar width when activeStep changes (might be a better way to do this)
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.documentElement.style.setProperty("--scrollbar-width", `${scrollbarWidth}px`);

    // scroll to top of page
    window.scrollTo(0, 0);

    // update isMobile and isTablet on resize
    const handleResize = () => {
      const isMobileQuery = window.matchMedia("(max-width: 767px)");
      const isTabletQuery = window.matchMedia("(min-width: 768px) and (max-width: 919px)");
      const isDesktopQuery = window.matchMedia("(min-width: 920px) and (max-width: 1279px)");

      setIsMobile(isMobileQuery.matches);
      setIsTablet(isTabletQuery.matches);
      setIsSmallDesktop(isDesktopQuery.matches);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [activeStep, isMobile]);

  const isHomepage = currentStep === "donate";

  const handleMore = () => {
    fetch(BASE_URL + "/donations?idx=" + (donors.length + 1) + "&size=" + (isMobile ? "10" : "14"))
      .then((response) => response.json())
      .then((json) => {
        setDonors([...donors, ...json]);

        if (json.length < (isMobile ? 10 : 14)) {
          setIsLastPage(true);
        }
      });
  };

  // useMatch to check if it's /:donorId, if so, set isEcardView to true
  const match = useMatch("/:donorId");
  useEffect(() => {
    setIsEcardView(match && activeStep === 0 ? true : false);
  }, [match, activeStep]);

  return (
    <>
      {steps[activeStep].showSky && <Sky />}

      {isHomepage && (
        <header className="header full-width">
          <div className="logos">
            <span className="logo-ff">First Forward</span>
            <span className="logo-fu"></span>
          </div>
          <div className="headingSvg"></div>
        </header>
      )}

      <main className="main" id="canvas-wrapper" ref={container} style={{ height: isHomepage ? "auto" : "100%" }}>
        <div className={`grid-layout content page-${currentStep}`}>
          <div className="html">
            <StepCounter activeStep={activeStep} goToStep={goToStep} />

            {steps[activeStep].title != "" && <h1 className={steps[activeStep].h1Class}>{steps[activeStep].title}</h1>}

            <Routes>
              {steps.map((step) => (
                <Route key={step.slug} path={step.slug} element={step.content} />
              ))}
              <Route path="/:donorId" element={<ViewEcard goToStep={goToStep} />} />
            </Routes>
          </div>
          {/* no building for done */}
          {currentStep !== "done" && !isEcardView && (
            <div
              className={`building ${isMobile ? "mobile" : ""} ${isTablet ? "tablet" : ""} ${
                steps[activeStep].stepName == "thank" ? "fireworks" : ""
              }`}
              ref={buildingRef}
              id="building"
            >
              <Canvas
                eventSource={container}
                // style={{ position: "fixed", top: 0, bottom: 0, left: 0, right: 0, overflow: "hidden", zIndex: "2" }}
                className="canvas"
                // orthographic // stylistic choice, you will have to adjust zoom in camera settings
                dpr={[1, 2]} // pixel ratio clamping, might help perf on high res screens
                // flat={true} // no tone mapping, stylistic choice
                camera={cameraSettings}
                gl={
                  {
                    // antialias: true, // default, can improve performance to remove
                    // alpha: true,
                  }
                }
              >
                {/* <Perf deepAnalyze={true} /> */}
                <directionalLight position={[0, 1, 0]} intensity={2} />
                <directionalLight position={[1, 0, 0]} intensity={0.5} />
                <directionalLight position={[0, 0, 1]} intensity={1} />
                {steps[activeStep].showBrick && (
                  <Brick
                    color={donation.brick_colour}
                    scale={donation.brick_colour ? (isMobile ? 1.5 : isTablet ? 0.65 : 0.7) : 0}
                    spinning={steps[activeStep].brickSpinning}
                  />
                )}
                {steps[activeStep].showBuilding && (
                  <group scale={1}>
                    {steps[activeStep].stepName == "thank" && !isMobile && <Fireworks />}
                    <Building
                      gui={gui}
                      step={steps[activeStep]}
                      selectedZone={donation.brick_zone}
                      scale={steps[activeStep].buildingScale}
                      cameraSettings={cameraSettings}
                    />
                  </group>
                )}
              </Canvas>
              {(currentStep === "payment" || currentStep === "thank") && (
                <>
                  <div
                    className={`chosen-brick ${currentStep === "color-picker" ? "hide" : ""} ${
                      donation?.amount >= 10000 ? "chosen-brick--rays" : ""
                    }`}
                  >
                    {donation && donation.brick_zone && (
                      <span className="text-size-large">
                        Your <span className="hide-mobile">{donation.brick_colour}</span> brick{" "}
                        <span className="hide-mobile">will be on the {`${ZONES.find((zone) => zone.value == donation.brick_zone).label}`}</span>
                      </span>
                    )}
                    <div className={`brick brick--${donation.brick_colour}`}>
                      {donation.amount >= 6000 && <Sparkles level={donation.amount >= 7500 ? 1 : 2} />}
                      <span className="brick-img"></span>
                    </div>
                    {donation && donation.amount && <span className="text-size-xlarge">${donation.amount / 100}</span>}
                  </div>
                </>
              )}
            </div>
          )}

          {isHomepage && !isEcardView && (
            <>
              <Gallery />
              <section className="land-acknowledgement">
                We honour that we do our work on the unceded ancestral homelands of the <span>Səl̓ílwətaʔ</span> (Tsleil-Waututh),{" "}
                <span>Xʷməθkwəy̓əm</span> (Musqueam), & <span>Sḵwx̱wú7mesh</span> (Squamish) peoples.
              </section>
              <Donors donors={donors} goToStep={goToStep} handleMore={handleMore} isLastPage={isLastPage} />
            </>
          )}
        </div>
      </main>

      {isHomepage && (
        <footer className="footer full-width">
          <div className="logos">
            <span className="logo-ff">First Forward</span>
            <span className="logo-fu"></span>
          </div>
          <div className="headingSvg"></div>
        </footer>
      )}
    </>
  );
};

export { App };
