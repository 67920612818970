import React, { useState, useEffect } from "react";
import * as stripeJs from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import { Loading } from "../Loading";
import { Payment } from "./5_Payment";
import { BASE_URL, BOOST_AMOUNTS } from "../common/constants";
import Donation from "../types/Donation";

const stripePromise = loadStripe("pk_test_H2qChS0miwSFofvIGqUJmcKp"); // Stripe Publishable Key

const Form = ({ donation, setDonation, goToNextStep }) => {
  // Stripe info
  // const [email, setEmail] = useState<string>('')
  const [message, setMessage] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const [paymentIntent, setPaymentIntent] = useState<stripeJs.PaymentIntent | undefined>(undefined);

  const [paymentComplete, setPaymentComplete] = useState<boolean>(false);
  const [done, setDone] = useState<boolean>(false);

  useEffect(() => {
    console.log("form paymentIntent change", paymentIntent);
  }, [paymentIntent]);

  useEffect(() => {
    console.log("form donation change", donation);
  }, [donation]);

  useEffect(() => {
    updatePaymentIntent(donation);
  }, [donation.amount]);

  // SUCCESS HANDLING ! - Comment out if_required to test
  // http://localhost:1234/payment?payment_intent=pi_3PnSraKrEYxnDvoE0LbYHiER&payment_intent_client_secret=pi_3PnSraKrEYxnDvoE0LbYHiER_secret_i6Ms9wva56gnVWe05qQbjaMfk&redirect_status=succeeded
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const clientSecret = params.get("payment_intent_client_secret");

    if (!clientSecret) {
      setIsLoading(false);
      return;
    } else {
      setIsLoading(true);
    }

    stripePromise.then((stripe) => {
      if (stripe) {
        stripe.retrievePaymentIntent(clientSecret).then((result) => {
          handlePaymentAttempt(result, undefined, "creditcard");
        });
      }
    });
  }, []);

  const updatePaymentIntent = async (donation: Donation) => {
    const res = await fetch(BASE_URL + "/donations/payment_intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        stripe_payment_intent_id: paymentIntent ? paymentIntent.id : null,
        donation: donation,
      }),
    });
    const data = await res.json();
    setPaymentIntent(data.payment_intent);
    return data.payment_intent;
  };

  // Apple Pay / Payment Request
  const handlePaymentMethod = async (e: stripeJs.PaymentRequestPaymentMethodEvent, stripe: stripeJs.Stripe) => {
    setIsSubmitting(true);
    setMessage(undefined);

    const nextPaymentIntent = await updatePaymentIntent(donation);

    if (nextPaymentIntent && !!nextPaymentIntent.client_secret && typeof nextPaymentIntent.errors === "undefined") {
      const result = await stripe.confirmCardPayment(
        nextPaymentIntent.client_secret,
        {
          payment_method: e.paymentMethod.id,
        },
        { handleActions: false }
      );

      handlePaymentAttempt(result, e, "applepay");
    } else {
      setIsSubmitting(false);
      setMessage(nextPaymentIntent.errors);
    }
  };

  // Stripe Form submit
  const handlePaymentSubmit = async (e: React.FormEvent<HTMLFormElement>, stripe: stripeJs.Stripe, elements: stripeJs.StripeElements) => {
    console.log("handlePaymentSubmit", e, stripe, elements);
    e.preventDefault();
    setIsSubmitting(true);
    setMessage(undefined);

    const nextPaymentIntent = await updatePaymentIntent(donation);

    if (nextPaymentIntent && typeof nextPaymentIntent.errors === "undefined") {
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Can get redirected elsewhere to handle payment
          // return_url: "https://denimandsteel.ngrok.io",
          return_url: window.location.href,
          payment_method_data: {
            billing_details: {
              email: donation.email, // Helps Stripe with fraud detection
            },
          },
        },
        redirect: "if_required", // Comment out to test parametrized SUCCESS HANDLING !
      });

      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.

      handlePaymentAttempt(result, undefined, "creditcard");
    } else {
      setIsSubmitting(false);
      setMessage(nextPaymentIntent.errors);
    }
  };

  const handlePaymentAttempt = async (
    { error, paymentIntent: finishedPaymentIntent }: stripeJs.PaymentIntentResult,
    e: stripeJs.PaymentRequestPaymentMethodEvent | undefined,
    method: "creditcard" | "applepay"
  ) => {
    setIsSubmitting(true);
    if (error) {
      setIsSubmitting(false);
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage(
          "There was a problem processing your payment. Please check your name, address, and payment information and try again. If the problem persists please contact your credit card issuer."
        );
      }
      if (e) {
        e.complete("fail");
      }
    } else if (finishedPaymentIntent) {
      switch (finishedPaymentIntent.status) {
        case "succeeded":
          setPaymentIntent(finishedPaymentIntent);
          setPaymentComplete(true);
          // setMessage("Payment succeeded!")
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage(
            "There was a problem processing your payment. Please check your name, address, and payment information and try again. If the problem persists please contact your credit card issuer."
          );
          break;
      }
    } else {
      setMessage(
        "There was a problem processing your payment. Please check your name, address, and payment information and try again. If the problem persists please contact your credit card issuer."
      );
    }

    if (finishedPaymentIntent && finishedPaymentIntent.status === "succeeded") {
      const res = await fetch(BASE_URL + "/donations/paid", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          stripe_payment_intent_id: finishedPaymentIntent.id,
        }),
      });
      const data = await res.json();
      setDonation(data.donation);
      setIsLoading(false);
      // setIsSubmitting(false);
      // pushAnalytics("purchase", "payments");
      if (e) {
        e.complete("success");
      }
      goToNextStep();
    }
  };

  // if (isSubmitting) {
  //   return (
  //     <div>
  //       Here1?
  //       <Loading />
  //     </div>
  //   );
  // }
  return (
    <div className="form">
      <div className="payment card card--stripe">
        {paymentIntent && paymentIntent.client_secret ? (
          <Elements
            key={paymentIntent.id}
            stripe={stripePromise}
            options={{
              clientSecret: paymentIntent.client_secret,
              fonts: [
                {
                  family: "Davis Sans",
                  src: "url(https://buyabrick.denimandsteel.com/DavisSans-Regular.5065064c.otf) format('opentype')",
                  weight: "normal",
                },
              ],
              appearance: {
                theme: "stripe",
                variables: {
                  gridRowSpacing: "16px",
                  colorPrimary: "#2d2a26",
                  colorBackground: "#fff",
                  focusOutline: "2px solid #5f96d4",
                  // fontWeightNormal: "bold",
                  fontFamily: "Davis Sans, sans-serif",
                  borderRadius: "10px",
                  fontSizeBase: "22px",
                  // spacingUnit: "18px",
                },
                rules: {
                  ".Label": {
                    fontSize: "16px",
                    // marginBottom: "8px",
                    // marginTop:
                  },
                  ".Input": {
                    border: "2px solid #c5dbf4",
                    padding: "12px 10px 10px 10px",
                    boxShadow: "none",
                  },
                  ".Input:focus": {
                    border: "2px solid #c5dbf4",
                    boxShadow: "none",
                  },
                  ".p-CardForm": {
                    marginTop: "var(--spacingGridRow)",
                  },
                },
              },
            }}
          >
            <Payment
              donation={donation}
              setDonation={setDonation}
              onPaymentMethod={handlePaymentMethod}
              onPaymentSubmit={handlePaymentSubmit}
              submitting={isSubmitting}
              validated={true}
              message={message}
            />
          </Elements>
        ) : (
          <div className="mt-4">
            <Loading />
          </div>
        )}
      </div>
    </div>
  );
};

export { Form };
