import React, { useState, useRef, useEffect } from "react";
import Donation from "./types/Donation";
import { DonorCardDialog } from "./DonorCardDialog";
import { TEMP_CORPORATE_DONORS, TEMP_LEADERSHIP_DONORS, TEMP_GOLDEN_DONORS } from "./common/constants";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Sparkles from "./Sparkles";

// Logos
import denimsteel from "../assets/logos/denimsteel.png";

const Donors = ({
  donors,
  goToStep,
  handleMore,
  isLastPage,
}: {
  donors: Donation[];
  goToStep: (step: number) => void;
  handleMore: () => void;
  isLastPage: boolean;
}) => {
  const [selectedDonor, setSelectedDonor] = useState<Donation | null>(null);
  const [dialogPosition, setDialogPosition] = useState<{ left: number; top: number }>({ left: 0, top: 0 });
  const goldenCardRefs = useRef<(HTMLElement | null)[]>([]);
  const donorCardRefs = useRef<(HTMLElement | null)[]>([]);
  const corporateCardRefs = useRef<(HTMLElement | null)[]>([]);
  const dialogRef = useRef<HTMLDivElement>(null);
  const sectionRefs = useRef<HTMLElement[] | null[]>([]);
  const isMobile = window.innerWidth < 640;

  const handleDonorCardClick = (donor: Donation, donorRef?: HTMLElement) => {
    setSelectedDonor(donor);

    if (donorRef) {
      let left = 0;
      let top = 0;
      const rect = donorRef.getBoundingClientRect();

      if (isMobile) {
        document.body.style.overflow = "hidden"; // lock scroll
      } else {
        left = rect.left;
        top = donorRef.offsetTop;
        const dialogWidth = 500;
        const viewportWidth = window.innerWidth;
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
        if (left + dialogWidth + scrollbarWidth > viewportWidth) {
          left = rect.right - dialogWidth;
        }
      }
      setDialogPosition({
        left: left,
        top: top,
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const isMobileClose = isMobile && dialogRef.current && dialogRef.current.contains(event.target);
      const isDesktopClose = !isMobile && dialogRef.current && !dialogRef.current.contains(event.target);

      if (isMobileClose || isDesktopClose) {
        setSelectedDonor(null);
        document.body.style.overflow = "auto"; // unlock scroll
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      if (isMobile) {
        document.body.style.overflow = "auto"; // unlock scroll
      }
    };
  }, []);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const createScrollTriggers = () => {
      sectionRefs.current.forEach((section, index) => {
        const colourFrom = sectionRefs.current[index - 1]?.getAttribute("data-colour") || "#f8f8f8";
        const colourTo = section.getAttribute("data-colour");

        const animation = gsap.fromTo(
          document.body,
          { backgroundColor: colourFrom },
          {
            backgroundColor: colourTo,
            duration: 0.5,
            immediateRender: false,
          }
        );

        ScrollTrigger.create({
          trigger: section,
          start: "top 90%",
          end: "bottom 90%",
          animation: animation,
          scrub: true,
          // markers: true,
        });
      });
    };

    // Initialize the scroll triggers
    createScrollTriggers();

    // Whenever donors update, refresh the triggers
    ScrollTrigger.refresh();

    // Cleanup function to kill ScrollTrigger instances
    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
      gsap.to(document.body, { backgroundColor: "#f8f8f8" }); // Reset background colour
    };
  }, [donors]); // Refresh on donors update

  return (
    <div className="donors" id="donors">
      <section className="golden-donors" data-colour="#f9d999" ref={(ref) => (sectionRefs.current[0] = ref)}>
        <h2 className="text-size-large meta">Lucky Bricks</h2>
        <p>
          There are 5 Lucky Bricks waiting to be unlocked by random donors. Each comes with the opportunity to attend our new building’s opening
          events as a guest of honour. Winners will be notified by email a few days after our donation.
        </p>

        <div className="donors-links">
          <button onClick={() => goToStep(1)} className="button">
            Buy a Brick for $50
          </button>

          <a href="https://firstunited.ca/donate-now/donate-to-first-forward" className="button button--s">
            Donate another amount
          </a>
        </div>

        <ul className="donors-list content">
          {TEMP_GOLDEN_DONORS.map((donor, index) =>
            donor.amount > 0 ? (
              <li key={`G-${donor.id}`} className="donor-card donor-card--mini" ref={(ref) => (goldenCardRefs.current[index] = ref)}>
                <button
                  className="donor-card-overlay"
                  onClick={() => handleDonorCardClick(donor, goldenCardRefs.current[index])}
                  id={`donor-card-${donor.id}`}
                  aria-label={`View details of ${donor.recognition_name_display}'s donation`}
                />

                <div className="donor-card-info">
                  <span className="donor-card-subheading text-size-xsmall meta">
                    Lucky brick {index + 1}
                    <br />
                    Discovered by
                  </span>
                  <span className="donor-card-heading text-size-medium"> {donor.recognition_name_display}</span>
                  <span className="donor-card-meta meta">
                    Brick <strong>{donor.id}</strong> · {donor.brick_zone.replace(/_/g, " ")}
                  </span>
                </div>

                <div className="donor-card-img brick brick--gold">
                  {donor.brick_level && donor.brick_level > 1 && <Sparkles level={donor.brick_level} />}
                  <span className="brick-img" />
                </div>
              </li>
            ) : (
              <li key={index} className="donor-card donor-card--mini donor-card--inactive">
                <div className="donor-card-info">
                  <span className="donor-card-subheading text-size-xsmall meta">Lucky brick {index + 1}</span>
                  <span className="donor-card-heading text-size-medium">Not yet found</span>
                </div>

                <div className="donor-card-img brick brick--gold">
                  <span className="brick-img" />
                </div>
              </li>
            )
          )}
        </ul>
      </section>

      <section className="individual-donors" data-colour="#f8f8f8" ref={(ref) => (sectionRefs.current[1] = ref)}>
        <h2 className="text-size-large meta">Individual Donors</h2>

        <ul className="donors-list content uneven">
          {donors.map((donor: Donation, index: number) => (
            <li
              key={donor.id}
              className={`donor-card donor-card--mini ${donor.brick_level === 4 ? "donor-card--burst" : ""}`}
              ref={(ref) => (donorCardRefs.current[index] = ref)}
            >
              <button
                className="donor-card-overlay"
                onClick={() => handleDonorCardClick(donor, donorCardRefs.current[index])}
                id={`donor-card-${donor.id}`}
                aria-label={`View details of ${donor.recognition_name_display}'s donation`}
              />
              <div className="donor-card-info">
                {donor.dedication && <span className="donor-card-subheading text-size-xxsmall meta">{donor.dedication.replace(/_/g, " ")} of</span>}
                <span className="donor-card-heading text-size-medium">
                  {donor.dedication ? donor.dedication_name : donor.recognition_name_display}
                </span>
                <span className="donor-card-meta text-size-xxsmall meta">
                  Brick <strong>{donor.id}</strong> · {donor.brick_zone.replace(/_/g, " ")}
                </span>
              </div>
              <div className={`donor-card-img brick brick--${donor.brick_colour}`}>
                {donor.brick_level && donor.brick_level > 1 && <Sparkles level={donor.brick_level} />}
                <span className="brick-img" />
              </div>
            </li>
          ))}
        </ul>

        {!isLastPage && (
          <button className="button button--s" onClick={handleMore}>
            Load More...
          </button>
        )}
      </section>

      <section className="corporate-donors" data-colour="#8ab7e9" ref={(ref) => (sectionRefs.current[2] = ref)}>
        <h2 className="text-size-large meta">Corporate Donors</h2>

        <ul className="donors-list content uneven">
          {TEMP_CORPORATE_DONORS.map((donor: Donation, index) => (
            <li key={donor.id} className="donor-card donor-card--mini" ref={(ref) => (corporateCardRefs.current[index] = ref)}>
              <button
                className="donor-card-overlay"
                onClick={() => handleDonorCardClick(donor, corporateCardRefs.current[index])}
                id={`donor-card-${donor.id}`}
                aria-label={`View details of ${donor.recognition_name_display}'s donation`}
              />
              <div className="donor-card-img logo">
                <img src={denimsteel} alt={`${donor.recognition_name_display}`} />
              </div>
            </li>
          ))}
        </ul>

        <div className="donors-links">
          <a href="mailto:firstforward@firstunited.ca" className="button">
            Become a corporate donor (minimum gift of $5,000)
          </a>
        </div>
      </section>

      <section className="leadership-donors" data-colour="#2d2a26" ref={(ref) => (sectionRefs.current[3] = ref)}>
        <h2 className="text-size-large meta">Leadership Donors</h2>

        <ul className="donors-list content">
          {TEMP_LEADERSHIP_DONORS.map((donor: Donation, index) => (
            <li key={index} className="donor-card">
              <span className="donor-card-subheading meta">XXX Partner</span>
              <div className="donor-card-img logo">
                <img src={denimsteel} alt={`${donor.recognition_name_display} Logo`} />
              </div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              <div className="donor-card-info">
                <span className="donor-card-subheading text-size-small meta">We donated because…</span>
                <span className="donor-card-heading text-size-medium">
                  First United provides <mark>{donor.reason_provides}</mark> to people who <mark>{donor.reason_who}</mark>.
                </span>
              </div>
            </li>
          ))}
        </ul>

        <div className="donors-links">
          <button onClick={() => goToStep(1)} className="button button--cta">
            Buy a Brick for $50
          </button>
        </div>
      </section>

      {selectedDonor && (
        <div
          className="donor-card-dialog"
          style={{ left: dialogPosition.left, top: dialogPosition.top }}
          ref={dialogRef}
          aria-labelledby={`donor-card-${selectedDonor.id}`}
          aria-modal="true"
        >
          <DonorCardDialog donor={selectedDonor} />
        </div>
      )}
    </div>
  );
};

export { Donors };
