import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { App } from "./App";

const hook = document.getElementById("three-hook") as HTMLDivElement | null;
if (hook) {
  const threeRoot = createRoot(hook);
  threeRoot.render(
    <Router>
      <App />
    </Router>
  );
}
