import React, { useState, useEffect, useRef } from "react";

import photo1 from "../assets/photos/photo-1.jpg";
import photo2 from "../assets/photos/photo-2.jpg";
import photo3 from "../assets/photos/photo-3.jpg";
import photo4 from "../assets/photos/photo-4.jpg";
import photo5 from "../assets/photos/photo-5.jpg";

const Gallery = () => {
  const [open, setOpen] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const dialogRef = useRef(null);

  const handleOpen = (photo) => {
    setOpen(true);
    setSelectedPhoto(photo);
    document.body.style.overflow = "hidden";
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPhoto(null);
    document.body.style.overflow = "auto";
  };

  useEffect(() => {
    if (dialogRef.current && open) {
      const handleClick = (e) => {
        if (dialogRef.current.contains(e.target)) {
          handleClose();
        }
      };

      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    }
  }, [open]);

  return (
    <>
      <section className="gallery" data-colour="#c5dbf4">
        <ul className="gallery-photos">
          {Array.from({ length: 5 }, (_, i) => i + 1).map((i) => (
            <li key={i}>
              <img
                src={i === 1 ? photo1 : i === 2 ? photo2 : i === 3 ? photo3 : i === 4 ? photo4 : photo5}
                alt=""
                onClick={() => handleOpen(i === 1 ? photo1 : i === 2 ? photo2 : i === 3 ? photo3 : i === 4 ? photo4 : photo5)}
              />
            </li>
          ))}
        </ul>

        <div className="gallery-video">
          <h2 className="text-size-large">
            Learn about the healing power of art and the significance of our new building’s brick facades, designed by Musqueam artist Debra Sparrow.
          </h2>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/Hlhiy-IoXsk?si=fMzwUx0E374_VhRD"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>

        {open && (
          <div className="gallery-dialog" ref={dialogRef}>
            <div className="gallery-dialog-content">
              <img src={selectedPhoto} alt="" />
            </div>
            <button onClick={handleClose} className="button dialog-close">
              <span className="visually-hidden">Dismiss</span>
            </button>
          </div>
        )}
      </section>
    </>
  );
};

export { Gallery };
