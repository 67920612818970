import * as THREE from 'three';

const gravity = new THREE.Vector3(0, -0.005, 0);
const textureSize = 128.0;
const friction = 0.998;
let canvasTexture;

// const mapLinear = ( x, a1, a2, b1, b2 ) => {
// 	return b1 + ( x - a1 ) * ( b2 - b1 ) / ( a2 - a1 );
// };


const drawRadialGradation = (ctx, canvasRadius, canvasW, canvasH) => {
	ctx.save();
	const gradient = ctx.createRadialGradient(canvasRadius, canvasRadius, 0, canvasRadius, canvasRadius, canvasRadius);
	gradient.addColorStop(0.0, 'rgba(255,255,255,1.0)');
	gradient.addColorStop(0.5, 'rgba(255,255,255,0.5)');
	gradient.addColorStop(1.0, 'rgba(255,255,255,0)');
	ctx.fillStyle = gradient;
	ctx.fillRect(0, 0, canvasW, canvasH);
	ctx.restore();
};

const getTexture = () => {
	const canvas = document.createElement('canvas');
	const ctx = canvas.getContext('2d');

	const diameter = textureSize;
	canvas.width = diameter;
	canvas.height = diameter;
	const canvasRadius = diameter / 2;

	/* gradation circle
		------------------------ */
	drawRadialGradation(ctx, canvasRadius, canvas.width, canvas.height);
	const texture = new THREE.Texture(canvas);
	texture.type = THREE.FloatType;
	texture.needsUpdate = true;
	return texture;
};

canvasTexture = getTexture();

const getRandomNum = (max = 0, min = 0) => Math.floor(Math.random() * (max + 1 - min)) + min;

// const mapLinear = ( x, a1, a2, b1, b2 ) => {
// 	return b1 + ( x - a1 ) * ( b2 - b1 ) / ( a2 - a1 );
// };

const getOffsetXYZ = i => {
	const offset = 3;
	const index = i * offset;
	const x = index;
	const y = index + 1;
	const z = index + 2;
	return { x, y, z };
};

const getOffsetRGBA = i => {
	const offset = 4;
	const index = i * offset;
	const r = index;
	const g = index + 1;
	const b = index + 2;
	const a = index + 3;
	return { r, g, b, a };
};

const getPointMesh = (num, vels, type) => {
	// geometry
	const bufferGeometry = new THREE.BufferGeometry();
	const vertices = [];
	const velocities = [];
	const colors = [];
	const adjustSizes = [];
	const masses = [];
	const colorType = true || Math.random() > 0.3 ? 'single' : 'multiple';
	const singleColor = getRandomNum(100, 20) * 0.01;
	const multipleColor = () => getRandomNum(100, 1) * 0.01;
	let rgbType;
	const rgbTypeDice = Math.random();
	if (rgbTypeDice > 0.66) {
		rgbType = 'red';
	} else if (rgbTypeDice > 0.33) {
		rgbType = 'yellow';
	} else if (rgbTypeDice > 0) {
		rgbType = 'black';
	} else {
		rgbType = 'white';
	}
	for (let i = 0; i < num; i++) {
		const pos = new THREE.Vector3(0, 0, 0);
		vertices.push(pos.x, pos.y, pos.z);
		velocities.push(vels[i].x, vels[i].y, vels[i].z);
		if (type === 'seed') {
			let size;
			if (type === 'trail') {
				size = Math.random() * 0.1 + 0.1;
			} else {
				size = Math.pow(vels[i].y, 2) * 0.04;
			}
			if (i === 0) size *= 1.1;
			adjustSizes.push(size);
			masses.push(size * 0.017);
			colors.push(1.0, 1.0, 1.0, 1.0);
		} else {
			const size = getRandomNum(300, 10) * 0.001;
			adjustSizes.push(size);
			masses.push(size * 0.017);
			if (colorType === 'multiple') {
				colors.push(multipleColor(), multipleColor(), multipleColor(), 1.0);
			} else {
				switch (rgbType) {
					case 'red':
						colors.push(singleColor, 0.1, 0.1, 1.0);
						break;
					case 'yellow':
						colors.push(singleColor, singleColor, 0.1, 1.0);
						break;
					case 'white':
						colors.push(singleColor, singleColor, singleColor, 1.0);
						break;
					case 'black':
						colors.push(0.1, 0.1, 0.1, 1.0);
						break;
					default:
						colors.push(singleColor, 0.1, 0.1, 1.0);
				}
			}
		}
	}
	bufferGeometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3).setUsage( THREE.DynamicDrawUsage ));
	bufferGeometry.setAttribute('velocity', new THREE.Float32BufferAttribute(velocities, 3).setUsage( THREE.DynamicDrawUsage ));
	bufferGeometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 4).setUsage( THREE.DynamicDrawUsage ));
	bufferGeometry.setAttribute('adjustSize', new THREE.Float32BufferAttribute(adjustSizes, 1).setUsage( THREE.DynamicDrawUsage ));
	bufferGeometry.setAttribute('mass', new THREE.Float32BufferAttribute(masses, 1).setUsage( THREE.DynamicDrawUsage ));
	// material
	const shaderMaterial = new THREE.RawShaderMaterial({
		uniforms: {
			size: {
				type: 'f',
				value: textureSize
			},
			texture: {
				type: 't',
				value: canvasTexture
			}
		},
		transparent: true,
		// Display of "blending: THREE.AdditiveBlending" does not work properly if "depthWrite" property is set to true.
		// Therefore, it is necessary to make it false in the case of making the image transparent by blending.
		depthWrite: false,
		blending: THREE.AdditiveBlending,
		vertexShader: `precision mediump float;
				attribute vec3 position;
				uniform mat4 projectionMatrix;
				uniform mat4 modelViewMatrix;
				uniform float size;
				attribute float adjustSize;
				uniform vec3 cameraPosition;
				varying float distanceCamera;
				attribute vec3 velocity;
				attribute vec4 color;
				varying vec4 vColor;
				void main() {
						vColor = color;
						vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
						gl_PointSize = size * adjustSize * (100.0 / length(modelViewPosition.xyz));
						gl_Position = projectionMatrix * modelViewPosition;
				}`,
		fragmentShader: `precision mediump float;
				uniform sampler2D texture;
				varying vec4 vColor;
				void main() {
						vec4 color = vec4(texture2D(texture, gl_PointCoord));
						gl_FragColor = color * vColor;
				}`
	});


	return new THREE.Points(bufferGeometry, shaderMaterial);
	// const otherShaderMaterial = new THREE.MeshPhongMaterial();
	// return new THREE.Points(bufferGeometry, otherShaderMaterial);
};

class ParticleMesh {
	constructor(num, vels, type) {
		this.particleNum = num;
		this.timerStartFading = 10;
		this.mesh = getPointMesh(num, vels, type);
	}
	update(gravity) {
		if (this.timerStartFading > 0) this.timerStartFading -= 0.3;
		const { position, velocity, color, mass } = this.mesh.geometry.attributes;
		const decrementRandom = () => (Math.random() > 0.5 ? 0.98 : 0.96);
		const decrementByVel = v => (Math.random() > 0.5 ? 0 : (1 - v) * 0.1);
		for (let i = 0; i < this.particleNum; i++) {
			const { x, y, z } = getOffsetXYZ(i);
			velocity.array[y] += gravity.y - mass.array[i];
			velocity.array[x] *= friction;
			velocity.array[z] *= friction;
			velocity.array[y] *= friction;
			position.array[x] += velocity.array[x];
			position.array[y] += velocity.array[y];
			position.array[z] += velocity.array[z];
			const { a } = getOffsetRGBA(i);
			if (this.timerStartFading <= 0) {
				color.array[a] *= decrementRandom() - decrementByVel(color.array[a]);
				if (color.array[a] < 0.001) color.array[a] = 0;
			}
		}
		position.needsUpdate = true;
		velocity.needsUpdate = true;
		color.needsUpdate = true;
	}
	disposeAll() {
		this.mesh.geometry.dispose();
		this.mesh.material.dispose();
	}
}

class ParticleSeedMesh extends ParticleMesh {
	constructor(num, vels) {
		super(num, vels, 'seed');
	}
	update(gravity) {
		const { position, velocity, color, mass } = this.mesh.geometry.attributes;
		const decrementRandom = () => (Math.random() > 0.3 ? 0.99 : 0.96);
		const decrementByVel = v => (Math.random() > 0.3 ? 0 : (1 - v) * 0.1);
		const shake = () => (Math.random() > 0.5 ? 0.05 : -0.05);
		const dice = () => Math.random() > 0.1;
		const _f = friction * 0.98;
		for (let i = 0; i < this.particleNum; i++) {
			const { x, y, z } = getOffsetXYZ(i);
			velocity.array[y] += gravity.y - mass.array[i];
			velocity.array[x] *= _f;
			velocity.array[z] *= _f;
			velocity.array[y] *= _f;
			position.array[x] += velocity.array[x];
			position.array[y] += velocity.array[y];
			position.array[z] += velocity.array[z];
			if (dice()) position.array[x] += shake();
			if (dice()) position.array[z] += shake();
			const { a } = getOffsetRGBA(i);
			color.array[a] *= decrementRandom() - decrementByVel(color.array[a]);
			if (color.array[a] < 0.001) color.array[a] = 0;
		}
		position.needsUpdate = true;
		velocity.needsUpdate = true;
		color.needsUpdate = true;
	}
}

class ParticleTailMesh extends ParticleMesh {
	constructor(num, vels) {
		super(num, vels, 'trail');
	}
	update(gravity) {
		const { position, velocity, color, mass } = this.mesh.geometry.attributes;
		const decrementRandom = () => (Math.random() > 0.3 ? 0.98 : 0.95);
		const shake = () => (Math.random() > 0.5 ? 0.05 : -0.05);
		const dice = () => Math.random() > 0.2;
		for (let i = 0; i < this.particleNum; i++) {
			const { x, y, z } = getOffsetXYZ(i);
			velocity.array[y] += gravity.y - mass.array[i];
			velocity.array[x] *= friction;
			velocity.array[z] *= friction;
			velocity.array[y] *= friction;
			position.array[x] += velocity.array[x];
			position.array[y] += velocity.array[y];
			position.array[z] += velocity.array[z];
			if (dice()) position.array[x] += shake();
			if (dice()) position.array[z] += shake();
			const { a } = getOffsetRGBA(i);
			color.array[a] *= decrementRandom();
			if (color.array[a] < 0.001) color.array[a] = 0;
		}
		position.needsUpdate = true;
		velocity.needsUpdate = true;
		color.needsUpdate = true;
	}
}

class BasicFIreWorks {
	constructor() {
		this.meshGroup = new THREE.Group();
		this.isExplode = false;
		const max = 400;
		const min = 150;
		this.petalsNum = getRandomNum(max, min);
		this.life = 150;
		this.seed = this.getSeed();
		this.meshGroup.add(this.seed.mesh);
		this.meshGroup.scale.set(.8, .8, .8);
		this.meshGroup.position.x = -200;
		this.meshGroup.position.y = -130;
		this.meshGroup.position.z = -200;
		this.flowerSizeRate = THREE.MathUtils.mapLinear(this.petalsNum, min, max, 0.4, 0.7);
		this.flower;
	}
	getSeed() {
		const num = 40;
		const vels = [];
		for (let i = 0; i < num; i++) {
			const vx = 0;
			const vy = i === 0 ? Math.random() * 0.0 + 0.4 : Math.random() * 0.0 + 0.2;
			const vz = 0;
			vels.push(new THREE.Vector3(vx, vy, vz));
		}
		const pm = new ParticleSeedMesh(num, vels);
		const x = Math.random() * 60 - 30;
		const y = -30;
		const z = Math.random() * 60 - 30;
		pm.mesh.position.set(x, y, z);
		return pm;
	}
	explode(pos) {
		this.isExplode = true;
		this.flower = this.getFlower(pos);
		this.meshGroup.add(this.flower.mesh);
		this.meshGroup.remove(this.seed.mesh);
		this.seed.disposeAll();
	}
	getFlower(pos) {
		const num = this.petalsNum;
		const vels = [];
		let radius;
		const dice = Math.random();

		if (dice > 0.5) {
			for (let i = 0; i < num; i++) {
				radius = getRandomNum(120, 60) * 0.01;
				const theta = THREE.MathUtils.degToRad(Math.random() * 180);
				const phi = THREE.MathUtils.degToRad(Math.random() * 360);
				const vx = Math.sin(theta) * Math.cos(phi) * radius;
				const vy = Math.sin(theta) * Math.sin(phi) * radius;
				const vz = Math.cos(theta) * radius;
				const vel = new THREE.Vector3(vx, vy, vz);
				vel.multiplyScalar(this.flowerSizeRate);
				vels.push(vel);
			}
		} else {
			const zStep = 180 / num;
			const trad = (360 * (Math.random() * 20 + 1)) / num;
			const xStep = trad;
			const yStep = trad;
			radius = getRandomNum(120, 60) * 0.01;
			for (let i = 0; i < num; i++) {
				const sphereRate = Math.sin(THREE.MathUtils.degToRad(zStep * i));
				const vz = Math.cos(THREE.MathUtils.degToRad(zStep * i)) * radius;
				const vx = Math.cos(THREE.MathUtils.degToRad(xStep * i)) * sphereRate * radius;
				const vy = Math.sin(THREE.MathUtils.degToRad(yStep * i)) * sphereRate * radius;
				const vel = new THREE.Vector3(vx, vy, vz);
				vel.multiplyScalar(this.flowerSizeRate);
				vels.push(vel);
			}
		}

		const particleMesh = new ParticleMesh(num, vels);
		particleMesh.mesh.position.set(pos.x, pos.y, pos.z);
		return particleMesh;
	}
	update(gravity) {
		if (!this.isExplode) {
			this.drawTail();
		} else {
			this.flower.update(gravity);
			if (this.life > 0) this.life -= 1;
		}
	}
	drawTail() {
		this.seed.update(gravity);
		const { position, velocity } = this.seed.mesh.geometry.attributes;
		let count = 0;
		let isComplete = true;
		// Check if the y-axis speed is down for all particles
		for (let i = 0, l = velocity.array.length; i < l; i++) {
			const v = velocity.array[i];
			const index = i % 3;
			if (index === 1 && v > 0) {
				count++;
			}
		}

		isComplete = count == 0;
		if (!isComplete) return;
		const { x, y, z } = this.seed.mesh.position;
		const flowerPos = new THREE.Vector3(x, y, z);
		let highestPos = 0;
		let offsetPos;
		for (let i = 0, l = position.array.length; i < l; i++) {
			const p = position.array[i];
			const index = i % 3;
			if (index === 1 && p > highestPos) {
				highestPos = p;
				offsetPos = new THREE.Vector3(position.array[i - 1], p, position.array[i + 2]);
			}
		}
		flowerPos.add(offsetPos);
		this.explode(flowerPos);
	}
}

class RichFIreWorks extends BasicFIreWorks {
	constructor() {
		super();
		const max = 150;
		const min = 100;
		this.petalsNum = getRandomNum(max, min);
		this.flowerSizeRate = THREE.MathUtils.mapLinear(this.petalsNum, min, max, 0.4, 0.7);
		this.tailMeshGroup = new THREE.Group();
		this.tails = [];
	}
	explode(pos) {
		this.isExplode = true;
		this.flower = this.getFlower(pos);
		this.tails = this.getTail();
		this.meshGroup.add(this.flower.mesh);
		this.meshGroup.add(this.tailMeshGroup);
	}
	getTail() {
		const tails = [];
		const num = 20;
		const { color: petalColor } = this.flower.mesh.geometry.attributes;

		for (let i = 0; i < this.petalsNum; i++) {
			const vels = [];
			for (let j = 0; j < num; j++) {
				const vx = 0;
				const vy = 0;
				const vz = 0;
				vels.push(new THREE.Vector3(vx, vy, vz));
			}
			const tail = new ParticleTailMesh(num, vels);

			const { r, g, b, a } = getOffsetRGBA(i);

			const petalR = petalColor.array[r];
			const petalG = petalColor.array[g];
			const petalB = petalColor.array[b];
			const petalA = petalColor.array[a];

			const { position, color } = tail.mesh.geometry.attributes;

			for (let k = 0; k < position.count; k++) {
				const { r, g, b, a } = getOffsetRGBA(k);
				color.array[r] = petalR;
				color.array[g] = petalG;
				color.array[b] = petalB;
				color.array[a] = petalA;
			}

			const { x, y, z } = this.flower.mesh.position;
			tail.mesh.position.set(x, y, z);
			tails.push(tail);
			this.tailMeshGroup.add(tail.mesh);
		}
		return tails;
	}
	update(gravity) {
		if (!this.isExplode) {
			this.drawTail();
		} else {
			this.flower.update(gravity);

			const { position: flowerGeometory } = this.flower.mesh.geometry.attributes;

			for (let i = 0, l = this.tails.length; i < l; i++) {
				const tail = this.tails[i];
				tail.update(gravity);
				const { x, y, z } = getOffsetXYZ(i);
				const flowerPos = new THREE.Vector3(
					flowerGeometory.array[x],
					flowerGeometory.array[y],
					flowerGeometory.array[z]
				);
				const { position, velocity } = tail.mesh.geometry.attributes;
				for (let k = 0; k < position.count; k++) {
					const { x, y, z } = getOffsetXYZ(k);
					const desiredVelocity = new THREE.Vector3();
					const tailPos = new THREE.Vector3(position.array[x], position.array[y], position.array[z]);
					const tailVel = new THREE.Vector3(velocity.array[x], velocity.array[y], velocity.array[z]);
					desiredVelocity.subVectors(flowerPos, tailPos);
					const steer = desiredVelocity.sub(tailVel);
					steer.normalize();
					steer.multiplyScalar(Math.random() * 0.0003 * this.life);
					velocity.array[x] += steer.x;
					velocity.array[y] += steer.y;
					velocity.array[z] += steer.z;
				}
				velocity.needsUpdate = true;
			}

			if (this.life > 0) this.life -= 1.2;
		}
	}
}

export { BasicFIreWorks, RichFIreWorks };