import React, { useRef } from "react";
import { BASE_URL } from "../common/constants";
import { Ecard } from "../Ecard";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { HashLink } from "react-router-hash-link";

const ShareEcard = ({ donation, goToStep, addTransitions }: { donation: any; goToStep: (step: number) => void; addTransitions: any }) => {
  const handleShare = () => {
    const shareUrl = BASE_URL + "/" + donation.transaction_id;
    const shareText = `I just bought a brick to support First United’s new installation of public art. See my brick and consider buying your own: ${shareUrl}`;

    if (navigator.share) {
      navigator
        .share({
          title: "First United Brick Campaign",
          text: shareText,
          url: shareUrl,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      // decide later what to do if share is not supported
      console.log("Share not supported");
    }
  };

  const sharePage = useRef(null);

  useGSAP(() => {
    const introAnimation = gsap.to(sharePage.current, { opacity: 1, duration: 0.5 });
    addTransitions(introAnimation, "ecard-share-intro", false); // intro animation

    const exitAnimation = gsap.to(sharePage.current, { opacity: 0, duration: 0.5 });
    addTransitions(exitAnimation, "ecard-share-exit", true); // exit animation
  }, [addTransitions]);

  return (
    <div className="share-ecard" ref={sharePage}>
      <p className="text-size-xlarge">Thank you!</p>
      <h1 className="heading">Your purchase is complete</h1>

      {donation && <Ecard donor={donation} goToStep={goToStep} />}

      <section>
        <h2 className="text-size-large bold">
          Your generosity helps us build a landmark installation of Indigenous public art in the Downtown Eastside.
        </h2>

        <button onClick={() => goToStep(1)} className="button">
          Buy Another Brick
        </button>

        <p>Help us spread the campaign and show off your brick using this custom link:</p>

        <div className="share-ecard-buttons">
          <button onClick={handleShare} className="button button--s">
            Share the Campaign
          </button>

          <HashLink to="/#donors" className="button button--s">
            View Donor List
          </HashLink>
        </div>
      </section>
    </div>
  );
};

export { ShareEcard };
