import React from "react";

const FormCompletionLoader = () => {
  return (
    <div className="form-completion-loader">
      <div className="loader-dots">
        <p className="text-size-xlarge">Processing Your Payment</p>
        <div className="dot-scaler">
          <div className="dot-falling"></div>
        </div>
      </div>
    </div>
  );
};

export { FormCompletionLoader };
