import React from "react";

export default function Sparkles({ level, isStatic }: { level: number; isStatic?: boolean }) {
  const isLv2 = level === 2;
  return (
    <svg
      className={`sparkles sparkles--${isLv2 ? "min" : "max"} ${isStatic ? "sparkles--static" : ""}`}
      width="87"
      height="80"
      viewBox="0 0 87 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={`${
          isLv2
            ? "M78.287 17L76 19.287L78.287 21.574L80.574 19.287L78.287 17Z"
            : "M49.7156 2.78165L47.4286 5.06863L49.7156 7.35562L52.0026 5.06864L49.7156 2.78165Z"
        }`}
        fill="#5f96d4"
      />
      <path
        d="M66.319 0C63.8219 7.89143 64.4733 7.24 56.5704 9.68C64.4619 12.1771 63.8104 11.5257 66.2504 19.4286C68.7476 11.5371 68.0962 12.1886 75.999 9.74857C68.1076 7.25143 68.759 7.90857 66.319 0Z"
        fill="#5f96d4"
      />

      {!isLv2 && (
        <>
          <path
            d="M13.0957 57.1454C10.77 64.5797 11.3872 63.9625 3.95288 66.2882C11.3872 68.614 10.77 67.9968 13.0957 75.4311C15.4215 67.9968 14.8043 68.614 22.2386 66.2882C14.8043 63.9625 15.4215 64.5797 13.0957 57.1454Z"
            fill="#5f96d4"
          />
          <path d="M37.1472 73.0105L34.8602 75.2975L37.1472 77.5845L39.4342 75.2975L37.1472 73.0105Z" fill="#5f96d4" />
          <path
            d="M81.1419 0C79.8333 4.18286 80.1819 3.83429 75.999 5.14286C80.1819 6.45143 79.8333 6.10286 81.1419 10.2857C82.4505 6.10286 82.1019 6.45143 86.2847 5.14286C82.1019 3.83429 82.4505 4.18286 81.1419 0Z"
            fill="#5f96d4"
          />
          <path
            d="M31.2272 70.7282C26.3472 73.2825 26.9644 73.2825 22.0844 70.7282C24.6386 75.6082 24.6386 74.9911 22.0844 79.8711C26.9644 77.3168 26.3472 77.3168 31.2272 79.8711C28.6729 74.9911 28.6729 75.6082 31.2272 70.7282Z"
            fill="#5f96d4"
          />
          <path
            d="M84.5418 14.1829C79.3589 16.8971 80.0104 16.8971 74.8275 14.1829C77.5418 19.3657 77.5418 18.7143 74.8275 23.8971C80.0104 21.1829 79.3589 21.1829 84.5418 23.8971C81.8275 18.7143 81.8275 19.3657 84.5418 14.1829Z"
            fill="#5f96d4"
          />
          <path d="M2.28699 54.2857L0 56.5727L2.28698 58.8597L4.57397 56.5727L2.28699 54.2857Z" fill="#5f96d4" />
        </>
      )}
    </svg>
  );
}
