import React, { useState, useEffect, useRef } from "react";
import { BASE_URL, DEDICATION_OPTIONS, REASON_PROVIDES_OPTIONS, REASON_WHO_OPTIONS } from "../common/constants";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

const EcardCustomizer = ({ setDonation, donation, goToNextStep, addTransitions }) => {
  const [showRest, setShowRest] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      console.log("showing rest");
      setShowRest(true);
    }, 5000);
    return () => clearTimeout(timeout);
  }, []);

  const submit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (donation.dedication === "none") {
      donation.dedication = null;
    }

    const res = await fetch(BASE_URL + "/donations/" + donation.stripe_payment_intent_id, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ donation }),
    });
    const data = await res.json();
    // if data.errors...
    setDonation(data);
    goToNextStep();
  };

  const customizerForm = useRef(null);

  useGSAP(() => {
    const introAnimation = gsap.to(customizerForm.current, { opacity: 1, duration: 0.5 });
    addTransitions(introAnimation, "ecard-custom-intro", false); // intro animation

    const exitAnimation = gsap.to(customizerForm.current, { opacity: 0, duration: 0.5 });
    addTransitions(exitAnimation, "ecard-custom-exit", true); // exit animation
  }, [addTransitions]);

  return (
    <form ref={customizerForm} className="form start-hidden" onSubmit={submit}>
      <p className="receipt-message">A receipt has been sent to {donation.email}</p>

      <div className={"show-rest " + (showRest ? "ready" : "")}>
        <h2 className="heading">Add Finishing Touches to Your Brick</h2>

        <fieldset className="dedication">
          <legend className="text-size-medium bold">Dedication Option</legend>
          <div className="dedication-radio">
            {DEDICATION_OPTIONS.map((option) => (
              <label key={option.label} className="card card--radio">
                <input
                  type="radio"
                  name="dedication"
                  value={option.value}
                  onChange={(e) => setDonation({ ...donation, dedication: e.target.value })}
                  defaultChecked={option.label === "None"}
                />
                <span className="p">{option.label}</span>
              </label>
            ))}
          </div>

          {donation.dedication !== "none" && donation.dedication && (
            <div className="card card--text">
              <label htmlFor="dedication_name">Name</label>
              <input
                type="text"
                id="dedication_name"
                name="dedication_name"
                onChange={(e) => setDonation({ ...donation, dedication_name: e.target.value })}
                required={donation.dedication !== "none"}
              />
            </div>
          )}
        </fieldset>

        <fieldset className="why-did-you-donate">
          <legend className="text-size-medium bold">Why did you donate today?</legend>
          <p className="text-size-medium">I bought a brick for First Forward because...</p>
          <p className="text-size-medium">
            First United provides
            <span className="select-wrapper">
              <select
                className="mark"
                name="reason_provides"
                id="reason_provides"
                onChange={(e) => setDonation({ ...donation, reason_provides: e.target.value })}
              >
                <option value="">Choose one</option>
                {REASON_PROVIDES_OPTIONS.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </span>
            <br />
            to people who
            <span className="select-wrapper">
              <select className="mark" name="reason_who" id="reason_who" onChange={(e) => setDonation({ ...donation, reason_who: e.target.value })}>
                <option value="">Choose one</option>
                {REASON_WHO_OPTIONS.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </span>
          </p>
        </fieldset>
        <button className="button" type="submit">
          Save and Finish
        </button>
      </div>
    </form>
  );
};

export { EcardCustomizer };
