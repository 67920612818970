import React, { useState, useRef } from "react";
import { BASE_URL, BOOST_AMOUNTS } from "../common/constants";
import { Form } from "./";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";

const Finish = ({ donation, setDonation, goToNextStep, addTransitions }) => {
  const [custom, setCustom] = React.useState(false);
  const [customAmount, setCustomAmount] = React.useState(100);
  const customInput = useRef<HTMLInputElement>(null);
  const customRadio = useRef<HTMLInputElement>(null);
  const finishForm = useRef<HTMLFormElement>(null);

  useGSAP(() => {
    const introAnimation = gsap.to(finishForm.current, { opacity: 1, duration: 0.5 });
    addTransitions(introAnimation, "finish-intro", false, 2.5); // intro animation

    const exitAnimation = gsap.to(finishForm.current, { opacity: 0, duration: 0.5 });
    addTransitions(exitAnimation, "finish-exit", true, 3); // exit animation
  }, [addTransitions]);

  return (
    <>
      <form ref={finishForm} className="start-hidden">
        <div className="card card--text">
          <label htmlFor="name">Full Name or Company Name</label>
          <input
            type="text"
            id="name"
            className="text-size-medium"
            name="recognition_name"
            value={donation.recognition_name || ""}
            required
            onChange={(e) => setDonation({ ...donation, recognition_name: e.target.value, payment_name: e.target.value })}
          />
        </div>
        <div className="checkbox">
          <label htmlFor="name_hidden">
            <input
              type="checkbox"
              id="name_hidden"
              name="name_hidden"
              className="visually-hidden"
              checked={donation.name_hidden || false}
              onChange={(e) => setDonation({ ...donation, name_hidden: e.target.checked })}
            />
            <span className="p">Don't publish this name</span>
          </label>
        </div>
        <p className="italic">You can dedicate or make your donation in honour of someone after payment</p>
        <h2 className="heading finish-heading">Can you boost your donation for a bigger impact?</h2>
        <p>
          Boosting your donation will add even stronger supports for those in need. Add a boost and we'll add some shine to your brick to honour your
          generosity.
        </p>

        <fieldset className="boost-cards">
          <legend className="visually-hidden">Boost Your Donation</legend>
          <label className="card card--boost">
            <input
              type="radio"
              className="radio-input"
              value={5000}
              checked={!custom ? donation.amount == 5000 : false}
              onChange={(e) => {
                setCustom(false);
                setDonation({ ...donation, amount: parseFloat(e.target.value) });
              }}
            />
            <div className="card--boost-text">
              <span className="text-size-medium">Standard donation</span>
              <span className="text-size-small">Total: $50</span>
            </div>
          </label>
          {BOOST_AMOUNTS.map((boostAmount: number | string) =>
            boostAmount !== "custom" ? (
              <label className="card card--boost" key={boostAmount}>
                <input
                  type="radio"
                  className="radio-input"
                  value={boostAmount}
                  checked={!custom ? donation.amount === boostAmount : false}
                  onChange={(e) => {
                    setCustom(false);
                    setDonation({ ...donation, amount: parseFloat(e.target.value) });
                  }}
                />
                <div className="card--boost-text">
                  <span className="text-size-medium">Boost your donation</span>
                  <span className="text-size-xlarge">Add ${Number(boostAmount / 100) - 50}</span>
                  <span className="text-size-small">Total: ${`${boostAmount / 100}`}</span>
                </div>
                <div className={`brick brick--${donation.brick_colour}`}>
                  <span className="brick-img"></span>
                </div>
              </label>
            ) : (
              <label className="card card--boost" key={boostAmount}>
                <div className="card--boost-text">
                  <span className="text-size-medium">Add your own boost amount</span>
                  <input
                    type="radio"
                    ref={customRadio}
                    className="radio-input"
                    value={boostAmount}
                    data-amount={boostAmount}
                    checked={custom}
                    onChange={(e) => {
                      setCustom(true);
                      setDonation({ ...donation, amount: Number(customAmount * 100) + 5000 });
                      customInput.current?.focus();
                    }}
                  />
                  <div className="card--boost-input">
                    <span className="text-size-xlarge color-blue">Add $</span>
                    <input
                      ref={customInput}
                      onWheel={(e) => (e.target as HTMLElement).blur()}
                      className="text-size-xlarge color-dark-blue"
                      name="customAmount"
                      step="1"
                      value={customAmount}
                      onClick={() => {
                        setCustom(true);
                        setDonation({ ...donation, amount: Number(customAmount * 100) + 5000 });
                      }}
                      onChange={(e) => {
                        if (Number(e.target.value) >= 0 && e.target.value !== "") {
                          setCustomAmount(parseFloat(e.target.value));
                          setDonation({ ...donation, amount: Number(e.target.value * 100) + 5000 });
                        } else if (e.target.value === "") {
                          setCustomAmount("");
                          setDonation({ ...donation, amount: 5000 });
                        }
                      }}
                    />
                  </div>
                  <span className="text-size-small">Total: ${customAmount + 50}</span>
                </div>
              </label>
            )
          )}
        </fieldset>

        <hr />
        <span className="heading total-amount">
          Checkout: <span className="bold">${donation.amount / 100}</span>
        </span>

        <div className="card card--text">
          <label htmlFor="payment_name">Donor Name for Tax Receipt</label>
          <input
            type="text"
            id="payment_name"
            className="text-size-medium"
            name="payment_name"
            value={donation.payment_name || ""}
            required
            onChange={(e) => setDonation({ ...donation, payment_name: e.target.value })}
          />
        </div>
      </form>
      <Form donation={donation} setDonation={setDonation} goToNextStep={goToNextStep} />
    </>
  );
};

export { Finish };
