import React, { useRef } from "react";
import { ZONES } from "../common/constants";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { add } from "maath/dist/declarations/src/vector2";

const ZonePicker = ({ donation, setDonation, goToNextStep, addTransitions }) => {
  const wallPicker = useRef(null);

  useGSAP(() => {
    const introAnimation = gsap.to(wallPicker.current, { opacity: 1, duration: 0.5 });
    addTransitions(introAnimation, "zone-intro", false); // intro animation

    const exitAnimation = gsap.to(wallPicker.current, { opacity: 0, duration: 0.5 });
    addTransitions(exitAnimation, "zone-exit", true); // exit animation
  }, [addTransitions]);

  const buildingSVG = (zone) => {
    return (
      <svg width="221px" height="279px" viewBox="0 0 221 279" xmlns="http://www.w3.org/2000/svg">
        <g id="building-outline" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="outline" transform="translate(2.2448, 1)" fillRule="nonzero" stroke="#8AB7E9">
            <line x1="161.505197" y1="93.9705" x2="107.755197" y2="62.94134" id="south"></line>
            <path d="M54.0051971,93.9705 L107.755197,62.94134 L54.0051971,93.9705 Z" id="east"></path>
            <polygon
              id="Path"
              points="0.255197091 62.94134 54.0051971 93.9705 54.0051971 131.56534 64.4589115 137.232297 64.4589115 113.268868 108.755447 138.974306 107.755197 276.5 0.255197091 214.971"
            ></polygon>
            <polygon
              id="Path"
              points="215.255197 62.94134 215.255197 214.971 107.755197 276.500004 108.986939 139.04568 153.964774 113.080574 153.964774 137.232297 161.505197 133.16434 161.505197 93.9705"
            ></polygon>
            <line x1="107.755197" y1="-3.55271368e-15" x2="2.4174959e-15" y2="62.5043931" id="west"></line>
            <line x1="215.49812" y1="62.5197218" x2="107.755197" y2="1.0658141e-14" id="north"></line>
            <line x1="109.225217" y1="86.6736024" x2="64.2146074" y2="112.843005" id="west-copy"></line>
            <line x1="154.212231" y1="112.661499" x2="108.755447" y2="86.6736024" id="north-copy"></line>
          </g>
          <polygon
            id="north"
            opacity={zone === "north" ? 1 : 0}
            fill="#8AB7E9"
            points="66.7037144 114.268868 66.7037144 138.830235 110.614708 162.807634 111.00025 139.974306"
          ></polygon>
          <g id="south" opacity={zone === "south" ? 1 : 0} transform="translate(111.1872, 88.2664)">
            <polygon
              fill="#8AB7E9"
              points="0.000184712246 8.93052881e-15 -1.71472811e-14 26.2400804 44.8841249 50.3262101 45.2698512 25.814168"
            ></polygon>
            <polygon id="see-through" fill="#C6E1FE" points="23.4528455 38.8407751 44.5328177 26.6122745 44.531183 50.7962162"></polygon>
          </g>
          <polygon
            id="lightwell"
            opacity={zone === "light_well" ? 1 : 0}
            stroke="#8AB7E9"
            fill="#8AB7E9"
            points="179.391219 86.2220364 163.871462 94.9705 163.871462 247 179.391219 237.992278"
          ></polygon>
        </g>
      </svg>
    );
  };

  return (
    <form id="wall-picker" className="start-hidden" ref={wallPicker} onSubmit={goToNextStep}>
      <fieldset className="card-grid">
        <legend className="visually-hidden">Choose a wall to place your brick</legend>
        {ZONES.map((zone) => (
          <label className={`card card--radio card--wall text-size-medium card-wall--${zone.value}`} key={zone.label}>
            <input
              type="radio"
              name="brick_zone"
              value={zone.value}
              checked={donation.brick_zone == zone.value}
              onChange={(e) => setDonation({ ...donation, brick_zone: e.target.value })}
            />
            {zone.label}
            <div className="wallSvg">{buildingSVG(zone.value)}</div>
          </label>
        ))}
      </fieldset>
      <button className="button" disabled={donation.brick_zone === null}>
        Continue
      </button>
    </form>
  );
};

export { ZonePicker };
