import React, { useRef, useEffect } from "react";
import { useFrame, useLoader } from "@react-three/fiber";
import { MeshStandardMaterial } from "three";
import { easing } from "maath";
import { BRICK_COLOURS } from "./common/constants";

import brickObj from "../models/brick-rounded.obj";
import { useLoader } from "@react-three/fiber";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";

const Brick = ({ color, scale, spinning }) => {
  const brickRef = useRef<mesh>();
  const brick = useLoader(OBJLoader, brickObj);

  const Y_SCALE = 1.428;

  useFrame((state, delta) => {
    if (spinning) {
      brickRef.current.rotation.y += delta * 0.75;
    } else {
      const nearest = Math.round(brickRef.current.rotation.y / Math.PI) * Math.PI;
      easing.damp3(brickRef.current.rotation, [0, nearest, 0], 0.15, delta);
    }

    // update scale
    easing.damp3(brickRef.current.scale, [scale, scale * Y_SCALE, scale], 0.35, delta);
  });

  useEffect(() => {
    // update colour
    brickRef.current.children[0].material = new MeshStandardMaterial({
      color: BRICK_COLOURS.find(({ value }) => value === color)?.color,
    });
  }, [color]);

  brick.children[0].material = new MeshStandardMaterial({
    color: BRICK_COLOURS.find(({ value }) => value === color)?.color || "#FFF",
    // normalMap: brickNormal,
    // roughnessMap: brickRoughness,
  });
  return (
    <>
      <mesh dispose={null} ref={brickRef} scale={0} rotation={[0, 0, 0]}>
        <primitive object={brick} dispose={null} deep={true} />
      </mesh>
    </>
  );
};

export { Brick };