import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Ecard } from "./Ecard";
import { BASE_URL } from "./common/constants";

const ViewEcard = ({ goToStep }: { goToStep: (step: number) => void }) => {
  const { donorId } = useParams<{ donorId: string }>();
  const [donor, setDonor] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(BASE_URL + "/donations/" + donorId, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((data) => {
        setDonor(data);
        setLoading(false);
        document.body.style.overflow = "hidden";
      });

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [donorId]);

  return (
    <>
      {loading ? (
        <>
          {/* Loading animation??? */}
          Loading...
        </>
      ) : (
        <dialog className="view-ecard">
          <div className="view-ecard-inner">
            <Ecard donor={donor} isView={true} goToStep={goToStep} />
          </div>
        </dialog>
      )}
    </>
  );
};

export { ViewEcard };
