import React, { useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import Donation from "./types/Donation";
import Sparkles from "./Sparkles";
import { gsap } from "gsap";
import buildingImg from "../assets/images/building-full.png";

const Ecard = ({ donor, isView, goToStep }: { donor: Donation; isView?: boolean; goToStep: (step: number) => void }) => {
  const ecardRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    gsap.fromTo(ecardRef.current, { opacity: 0, y: "100%" }, { opacity: 1, y: "0%", duration: 1 });
  }, []);

  useEffect(() => {
    if (isView) {
      const handleClickOutside = (event) => {
        if (ecardRef.current && !ecardRef.current.contains(event.target)) {
          goToStep(1);
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [navigate, isView]);

  return (
    <div className="ecard" ref={ecardRef}>
      <div className="ecard-inner">
        <div className="ecard-body desktop octagon">
          <div>
            <h2 className="heading text-family-sans">
              <span className="bold">{donor.recognition_name_display}</span>
              <span className="light text-size-medium">bought a brick to support First United</span>
            </h2>
          </div>

          {donor.reason_provides && donor.reason_who && (
            <>
              <span className="ecard-divider"></span>

              <p className="ecard-body-reason text-size-medium">
                <span className="text-size-small meta">I donated because…</span>
                <br />
                First United provides <mark>{donor.reason_provides}</mark> to people who <mark>{donor.reason_who}</mark>
              </p>
            </>
          )}

          {donor.dedication && (
            <>
              <span className="ecard-divider mobile"></span>

              <p className="text-size-medium mobile">
                <span className="text-size-small meta">{donor.dedication.replace(/_/g, " ")} of</span>
                {donor.dedication_name}
              </p>
            </>
          )}

          {isView && (
            <>
              <span className="ecard-divider desktop"></span>

              <div className="ecard-body-links">
                <p>Help First United build a more compassionate future</p>
                <button className="button button--block" onClick={() => goToStep(1)}>
                  Buy a Brick Today
                </button>
                <Link className="button button--s" to="/">
                  Learn More About the Campaign
                </Link>
              </div>
            </>
          )}
        </div>

        <div className={`ecard-brick octagon octagon--sky ${donor.brick_level === 4 ? "ecard-brick--burst" : ""}`}>
          <span className="text-size-small meta">
            Brick <strong>{donor.id}</strong>
          </span>
          <div className={`brick brick--${donor.brick_level} brick--${donor.brick_colour}`}>
            {donor.brick_level && donor.brick_level > 1 && <Sparkles level={donor.brick_level} />}
            <span className="brick-img" />
          </div>
        </div>

        <div className="ecard-building octagon octagon--sky">
          {donor.dedication && (
            <p className="text-size-medium">
              <span className="text-size-small meta">{donor.dedication.replace(/_/g, " ")} of</span>
              {donor.dedication_name}
            </p>
          )}
          <div className={`ecard-building-img ${donor.dedication ? "" : "full"}`}>
            <img src={buildingImg} alt="First United Building" />
          </div>
        </div>

        {isView && (
          <div className="ecard-body mobile octagon">
            <div className="ecard-body-links">
              <p>Help First United build a more compassionate future</p>
              <button className="button button--block" onClick={() => goToStep(1)}>
                Buy a Brick Today
              </button>
              <button className="button button--s" onClick={() => goToStep(0)}>
                Learn More
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { Ecard };
