import React from "react";
import { ProgressBar } from "../ProgressBar";

const Intro = ({ goToNextStep, resetDonation }) => {
  const handleStart = () => {
    resetDonation();
    goToNextStep();
  };
  return (
    <>
      <section className="intro" id="campaign-intro" data-colour="#c5dbf4">
        <h2 className="heading">Help us create landmark public art in the Downtown Eastside</h2>

        <div>
          <p>
            Representation of Indigenous culture is a key part of reconciliation and healing in the Downtown Eastside. That’s why incorporating
            Indigenous artwork is an important part of our redevelopment project.
          </p>
          <p>
            Our new facility features beautiful brick facades designed by Musqueam artist Debra Sparrow. These works, spanning multiple stories on our
            building’s exterior, make the healing power of art accessible to all. Join us and help bring this inspiring installation to life.
          </p>
        </div>

        <ul className="intro-actions">
          <div className="text-size-medium">Campaign Progress</div>

          <ProgressBar moneyRaised={65300} />
          <button onClick={handleStart} className="button button--cta button--block">
            Buy a Brick for $50
          </button>

          <div className="intro-actions-links">
            <a href="https://firstunited.ca/donate-now/donate-to-first-forward" className="button button--s">
              Donate another amount
            </a>

            <a href="#" className="button button--s">
              Buying 3 or more bricks? Contact us
            </a>

            <a href="mailto:firstforward@firstunited.ca" className="button button--s">
              Become a Corporate Donor
            </a>
          </div>
        </ul>
      </section>
    </>
  );
};

export { Intro };
