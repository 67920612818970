import React, { useState, useRef, useEffect } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { Clone, Image, useHelper } from "@react-three/drei";
import { easing } from "maath";
import { linear } from "maath/easing";
import { TransformControls } from "@react-three/drei";
import { MeshStandardMaterial, Color, Vector3, DirectionalLightHelper } from "three";
import { useGLTF, Text } from "@react-three/drei";
import * as THREE from "three";
import buildingStairwell from "../assets/images/building/east-lightwell.png";
import buildingSouthElevation from "../assets/images/building/south-elevation.png";
import buildingCourtyardNorth from "../assets/images/building/courtyard-north.png";
import buildingEastLeft from "../assets/images/building/east-elevation-left.png";
import buildingEastRight from "../assets/images/building/east-elevation-right.png";

const Davis = "https://s3.amazonaws.com/denimandsteel/2024-09-first-united/fonts/DavisSans-Regular.otf";
const dipAmount = Math.PI / 1;
let stairWellScale = { scale: 38.13 };
let southElevationScale = { scale: 160 };
let courtyardNorthScale = { scale: 160 };
let eastLeftScale = { scale: 160 };
let eastRightScale = { scale: 160 };

let nextLookAt = new Vector3(0, 0, 0);
let nextPos = new Vector3(0, 0, 0);

import buildingObj from "../models/building-with-cube-removed-24-optimized-draco.glb";

const Building = ({ step, selectedZone, gui, scale, cameraSettings }) => {
  const { camera } = useThree();
  const ref = useRef<null | THREE.Mesh>(null);
  const overallRef = useRef<null | THREE.Mesh>(null);
  const hastingsRef = useRef<null | THREE.Mesh>(null);
  const goreRef = useRef<null | THREE.Mesh>(null);

  const stairWellRef = useRef<null | THREE.Mesh>(null);
  const southElevationRef = useRef<null | THREE.Mesh>(null);
  const courtyardNorthRef = useRef<null | THREE.Mesh>(null);
  const eastLeftRef = useRef<null | THREE.Mesh>(null);
  const eastRightRef = useRef<null | THREE.Mesh>(null);

  const { nodes, scene } = useGLTF(buildingObj);
  const [reactivity, setReactivity] = useState(0);

  const scaleDuration = step.stepName == "donate" ? 0.45 : 0.1;
  useFrame((state, delta) => {
    if (ref && ref.current && overallRef && overallRef.current) {
      // easing.damp3(
      //   overallRef.current.rotation,
      //   [
      //     0,
      //     -1 * dipAmount + (1 - reactivity) * dipAmount,
      //     // 0,
      //     dipAmount + (1 - reactivity) * -1 * dipAmount,
      //   ],
      //   0.15,
      //   delta
      // );
      if (step.buildingAnimate && !selectedZone) {
        ref.current.rotation.y = (ref.current.rotation.y + delta * 0.25) % (Math.PI * 2);
      } else {
        easing.damp3(ref.current.rotation, [0, 0, 0], 0.15, delta);
      }

      const positionSpeed = 0.4;
      const zoomSpeed = 0.4;
      const lookAtSpeed = 0.4;
      const maxSpeed = 0.1;

      easing.damp3(camera.position, cameraSettings.position, positionSpeed, delta);

      easing.damp3(nextLookAt, cameraSettings.target, lookAtSpeed, delta);
      camera.lookAt(nextLookAt);

      easing.damp(camera, "zoom", cameraSettings.zoom, zoomSpeed, delta);
      camera.updateProjectionMatrix();
      easing.damp3(ref.current.scale, scale, scaleDuration, delta);
    }
  });

  // useEffect(() => {
  //   const onMove = (e) => {
  //     setReactivity(e.clientY / window.innerWidth);
  //   };
  //   window.addEventListener("mousemove", onMove);
  //   return () => {
  //     window.removeEventListener("mousemove", onMove);
  //   };
  // }, []);

  useEffect(() => {
    gui.current.add(overallRef.current.rotation, "x").min(-Math.PI).max(Math.PI).step(0.01);
    gui.current.add(overallRef.current.rotation, "y").min(-Math.PI).max(Math.PI).step(0.01);
    gui.current.add(overallRef.current.rotation, "z").min(-Math.PI).max(Math.PI).step(0.01);

    // gui.current.add(stairWellScale, 'scale').min(35).max(42).step(0.01);
    // gui.current.add(stairWellRef.current.position, 'x').min(18).max(22).step(0.01);
    // gui.current.add(stairWellRef.current.position, 'y').min(20).max(28).step(0.01);
    // gui.current.add(stairWellRef.current.position, 'z').min(-4).max(0).step(0.01);

    // gui.current.add(southElevationScale, 'scale').min(150).max(180).step(0.01);
    // gui.current.add(southElevationRef.current.position, 'x').min(8).max(12).step(0.01);
    // gui.current.add(southElevationRef.current.position, 'y').min(8).max(12).step(0.01);
    // gui.current.add(southElevationRef.current.position, 'z').min(18).max(24).step(0.01);
    // gui.current.add(southElevationRef.current.rotation, 'y').min(-Math.PI).max(Math.PI).step(0.01);

    // gui.current.add(courtyardNorthScale, 'scale').min(150).max(180).step(0.01);
    // gui.current.add(courtyardNorthRef.current.position, 'x').min(0).max(12).step(0.01);
    // gui.current.add(courtyardNorthRef.current.position, 'y').min(0).max(12).step(0.01);
    // gui.current.add(courtyardNorthRef.current.position, 'z').min(0).max(24).step(0.01);
    // gui.current.add(courtyardNorthRef.current.rotation, 'y').min(-Math.PI*2).max(Math.PI*2).step(0.01);

    // gui.current.add(eastLeftScale, 'scale').min(150).max(180).step(0.01);
    // gui.current.add(eastLeftRef.current.position, 'x').min(-10).max(50).step(0.01);
    // gui.current.add(eastLeftRef.current.position, 'y').min(-10).max(50).step(0.01);
    // gui.current.add(eastLeftRef.current.position, 'z').min(-10).max(50).step(0.01);
    // gui.current.add(eastLeftRef.current.rotation, 'y').min(-Math.PI*2).max(Math.PI*2).step(0.01);

    // gui.current.add(eastRightScale, "scale").min(150).max(180).step(0.01);
    // gui.current.add(eastRightRef.current.position, "x").min(-10).max(50).step(0.01);
    // gui.current.add(eastRightRef.current.position, "y").min(-10).max(50).step(0.01);
    // gui.current.add(eastRightRef.current.position, "z").min(-10).max(50).step(0.01);
    // gui.current
    //   .add(eastRightRef.current.rotation, "y")
    //   .min(-Math.PI * 2)
    //   .max(Math.PI * 2)
    //   .step(0.01);

    // gui.current.add(stairWellRef.current.rotation, 'x').min(-Math.PI).max(Math.PI).step(0.01);
    // gui.current.add(stairWellRef.current.rotation, 'y').min(-Math.PI).max(Math.PI).step(0.01);
    // gui.current.add(stairWellRef.current.rotation, 'z').min(-Math.PI).max(Math.PI).step(0.01);
    // const hastingsFolder = gui.current.addFolder('Hastings');
    // hastingsFolder.addColor(hastingsRef.current, 'color');
    // // hastingsFolder.add(hastingsRef.current.position, 'x').min(-50).max(50).step(1);
    // hastingsFolder.add(hastingsRef.current, 'letterSpacing').min(0).max(1).step(0.01);
    // hastingsFolder.add(hastingsRef.current, 'fontSize').min(0).max(5).step(0.05);
    // hastingsFolder.add(hastingsRef.current.rotation, 'z').min(-Math.PI).max(Math.PI).step(0.01);
  }, []);

  // window.tylor = nodes
  if (!scene) return null;
  scene.traverse((child) => {
    if (child instanceof THREE.Mesh) {
      child.material = new MeshStandardMaterial({
        color: "white",
      });
    }
  });

  return (
    <>
      <group ref={overallRef}>
        <group ref={ref} scale={0} position={[0, -1, 0]}>
          <primitive object={scene} position={[0, 0, 0]} />

          <Text
            font={Davis}
            ref={hastingsRef}
            color="#8cb7e6" // #93B6E5
            position={[-25, 0, 0]}
            rotation={[-Math.PI / 2, 0, -1.41]}
            // scale={[4, 4, 4]}
            anchorX="center" // default
            anchorY="middle" // default
            letterSpacing={0.45}
            fontSize={2.35}
          >
            GORE AVE
          </Text>
          <Text
            ref={goreRef}
            color="#8cb7e6" // #93B6E5
            position={[0, 0, -25]}
            rotation={[-Math.PI / 2, 0, -Math.PI]}
            anchorX="center" // default
            anchorY="middle" // default
            letterSpacing={0.45}
            fontSize={2.35}
          >
            E HASTINGS ST
          </Text>
          <Image
            ref={stairWellRef}
            url={buildingStairwell}
            position={[19.1, 24.07, -1.08]}
            rotation={[0, Math.PI / 2, 0]}
            scale={[165 / stairWellScale.scale, 1929 / stairWellScale.scale, 0]}
          />
          <Image
            ref={southElevationRef}
            url={buildingSouthElevation}
            position={[11.02, 10.21, 19.1]}
            rotation={[0, 0, 0]}
            scale={[2508 / southElevationScale.scale, 841 / southElevationScale.scale, 0]}
          />
          <Image
            ref={courtyardNorthRef}
            url={buildingCourtyardNorth}
            position={[11.15, 10.2, 11.0]}
            rotation={[0, Math.PI, 0]}
            scale={[2508 / courtyardNorthScale.scale, 841 / courtyardNorthScale.scale, 0]}
          />
          <Image
            ref={eastLeftRef}
            url={buildingEastLeft}
            position={[19, 10.33, 18.62]}
            rotation={[0, Math.PI / 2, 0]}
            scale={[183 / eastLeftScale.scale, 841 / eastLeftScale.scale, 0]}
          />
          <Image
            ref={eastRightRef}
            url={buildingEastRight}
            position={[19, 10.33, 11.61]}
            rotation={[0, Math.PI / 2, 0]}
            scale={[171 / eastRightScale.scale, 841 / eastRightScale.scale, 0]}
          />
        </group>
      </group>
    </>
  );
};

export { Building };
