// Constants for the application
import { expo } from "maath/dist/declarations/src/easing";
import { Vector3 } from "three";
import Donation from "../types/Donation";
import ZoneOption from "../types/ZoneOption";

export const BASE_URL =
  window.location.hostname === "buyabrick.denimandsteel.com" ? "https://api.buyabrick.denimandsteel.com" : "http://localhost:3000";

export const DEFAULT_DONATION: Donation = {
  amount: 5000,
  payment_status: "pending",
  brick_zone: null,
  brick_colour: null,
};

export const DEFAULT_CAMERA_SETTINGS = {
  fov: 45, // deg
  near: 0.01, // near and far will help performance, but watch for clipping
  // far: 200,
  position: new Vector3(10, 10, 10), // default position
  // rotation: new Vector3(-0.7854, 0.6155, 0.5236),
  target: new Vector3(0, 0, 0),
  zoom: 4,
  zoomMobile: 4,
};

// Zones Options
export const ZONES: ZoneOption[] = [
  {
    label: "North Deck Wall",
    value: "north",
    position: new Vector3(0.55, -0.27, 1.3),
    target: new Vector3(0.55, -0.27, 1),
    zoom: 0.3,
    zoomMobile: 0.4,
  },
  {
    label: "South Deck Wall",
    value: "south",
    position: new Vector3(0.55, -0.27, 0.2),
    target: new Vector3(0.55, -0.27, 0.6),
    zoom: 0.3,
    zoomMobile: 0.4,
  },
  {
    label: "Light Well Wall",
    value: "light_well",
    position: new Vector3(4.4, 0.2, 0),
    target: new Vector3(1.5, 0.2, 0),
    zoom: 1.1,
    zoomMobile: 1.1,
  },
];

export const BRICK_COLOURS: {
  label: string;
  value: string;
  color: string;
}[] = [
  {
    label: "White",
    value: "white",
    // color: "#FFF",
    color: "#FAF5F4",
  },
  {
    label: "Yellow",
    value: "yellow",
    // color: "#E1BF83",
    // color: "#E1B37D",
    color: "#FFBC5D",
  },
  {
    label: "Red",
    value: "red",
    // color: "#954A4A",
    // color: "#7B443E",
    color: "#BD2222",
  },
  {
    label: "Black",
    value: "black",
    // color: "#414141",
    color: "#323B46",
    // color: "#D6D6D6",
  },
];

// Dedication Options
export const DEDICATION_OPTIONS = [
  {
    label: "None",
    value: "none",
  },
  {
    label: "In Honor of",
    value: "in_honour",
  },
  {
    label: "In Memory of",
    value: "in_memory",
  },
];

export const REASON_PROVIDES_OPTIONS = ["education", "essential services", "food", "clothing"];

export const REASON_WHO_OPTIONS = ["are my neighbours", "are hungry", "need clothes"];

// Boost Amounts
export const BOOST_AMOUNTS = [6000, 7500, 10000, "custom"];

// TEMP DATA
export const TEMP_GOLDEN_DONORS: Donation[] = [
  {
    id: 1,
    recognition_name_display: "John Doe",
    brick_zone: "light_well",
    brick_colour: "red",
    brick_level: 2,
    reason_provides: "education",
    reason_who: "are my neighbours",
    dedication: "in_honour",
    dedication_name: "Jane Doe",
    amount: 1000,
    payment_status: "succeeded",
  },
  {
    id: 2,
    brick_zone: null,
    brick_colour: null,
    amount: 0,
    payment_status: "pending",
  },
  {
    id: 3,
    recognition_name_display: "Adam Smith",
    brick_zone: "north",
    brick_colour: "black",
    brick_level: 2,
    reason_provides: "education",
    reason_who: "are my neighbours",
    dedication: null,
    amount: 200,
    payment_status: "succeeded",
  },
  {
    id: 4,
    brick_zone: null,
    brick_colour: null,
    amount: 0,
    payment_status: "pending",
  },
  {
    id: 5,
    brick_zone: null,
    brick_colour: null,
    amount: 0,
    payment_status: "pending",
  },
];

export const TEMP_CORPORATE_DONORS: Donation[] = [
  {
    id: 1,
    recognition_name_display: "GreenTech Inc.",
    brick_zone: "north",
    brick_colour: "yellow",
    brick_level: 3,
    reason_provides: "education",
    reason_who: "are my neighbours",
    logoUrl: "denimsteel.png",
    amount: 40000,
    payment_status: "succeeded",
  },
  {
    id: 2,
    recognition_name_display: "Pinnacle Properties",
    brick_zone: "east",
    brick_colour: "white",
    brick_level: 4,
    reason_provides: "food",
    reason_who: "need clothes",
    logoUrl: "denimsteel.png",
    amount: 50000,
    payment_status: "succeeded",
  },
  {
    id: 3,
    recognition_name_display: "Riverview Restaurants",
    brick_zone: "light_well",
    brick_colour: "red",
    brick_level: 4,
    reason_provides: "clothing",
    reason_who: "are my neighbours",
    logoUrl: "denimsteel.png",
    amount: 55000,
    payment_status: "succeeded",
  },
  {
    id: 4,
    recognition_name_display: "Nova Spas",
    brick_zone: "north",
    brick_colour: "black",
    brick_level: 4,
    reason_provides: "essential services",
    reason_who: "are hungry",
    logoUrl: "denimsteel.png",
    amount: 45000,
    payment_status: "succeeded",
  },
  {
    id: 5,
    recognition_name_display: "GreenTech Inc.",
    brick_zone: "north",
    brick_colour: "yellow",
    brick_level: 3,
    reason_provides: "education",
    reason_who: "are my neighbours",
    logoUrl: "denimsteel.png",
    amount: 40000,
    payment_status: "succeeded",
  },
  {
    id: 6,
    recognition_name_display: "Pinnacle Properties",
    brick_zone: "east",
    brick_colour: "white",
    brick_level: 4,
    reason_provides: "food",
    reason_who: "need clothes",
    logoUrl: "denimsteel.png",
    amount: 50000,
    payment_status: "succeeded",
  },
  {
    id: 7,
    recognition_name_display: "Riverview Restaurants",
    brick_zone: "light_well",
    brick_colour: "red",
    brick_level: 4,
    reason_provides: "clothing",
    reason_who: "are my neighbours",
    logoUrl: "denimsteel.png",
    amount: 55000,
    payment_status: "succeeded",
  },
  {
    id: 8,
    recognition_name_display: "Nova Spas",
    brick_zone: "north",
    brick_colour: "black",
    brick_level: 4,
    reason_provides: "essential services",
    reason_who: "are hungry",
    logoUrl: "denimsteel.png",
    amount: 45000,
    payment_status: "succeeded",
  },
];

export const TEMP_LEADERSHIP_DONORS: Donation[] = [
  {
    id: 1,
    recognition_name_display: "GreenTech Inc.",
    brick_zone: "north",
    brick_colour: "yellow",
    brick_level: 3,
    reason_provides: "education",
    reason_who: "are my neighbours",
    logoUrl: "https://via.placeholder.com/120x60",
    amount: 40000,
    payment_status: "succeeded",
  },
  {
    id: 2,
    recognition_name_display: "Pinnacle Properties",
    brick_zone: "east",
    brick_colour: "white",
    brick_level: 4,
    reason_provides: "food",
    reason_who: "need clothes",
    logoUrl: "https://via.placeholder.com/160x120",
    amount: 50000,
    payment_status: "succeeded",
  },
  {
    id: 3,
    recognition_name_display: "Riverview Restaurants",
    brick_zone: "light_well",
    brick_colour: "red",
    brick_level: 4,
    reason_provides: "clothing",
    reason_who: "are my neighbours",
    logoUrl: "https://via.placeholder.com/160x160",
    amount: 55000,
    payment_status: "succeeded",
  },
];
