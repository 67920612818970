import React from "react";
import Donation from "./types/Donation";
import Sparkles from "./Sparkles";

import denimsteel from "../assets/logos/denimsteel.png";

const DonorCardDialog = ({ donor }: { donor: Donation }) => {
  const isIndividual = donor.logoUrl == null;

  return (
    <dialog className={`donor-card ${isIndividual && donor.brick_level === 4 ? "donor-card--burst" : ""}`}>
      {isIndividual ? (
        <>
          <div className="donor-card-info">
            <span className="donor-card-meta text-size-small meta">
              Brick <strong>{donor.id}</strong> · {donor.brick_zone.replace(/_/g, " ")}
            </span>
            <span className="donor-card-heading heading bold text-family-sans">{donor.recognition_name_display}</span>
            {donor.dedication && (
              <div className="donor-card-dedication">
                <span className="donor-card-subheading text-size-small meta">{donor.dedication.replace(/_/g, " ")} of</span>
                <span className="text-size-medium bold">{donor.dedication_name}</span>
              </div>
            )}
          </div>
          <div className={`donor-card-img brick brick--${donor.brick_colour}`}>
            {donor.brick_level && donor.brick_level > 1 && <Sparkles level={donor.brick_level} />}
            <span className="brick-img" />
          </div>
        </>
      ) : (
        <div className="donor-card-img logo">
          <img src={isIndividual ? donor.logoUrl : denimsteel} alt={`${donor.recognition_name_display}`} />
        </div>
      )}
      {donor.reason_provides && donor.reason_who && (
        <div className="donor-card-reason">
          <span className="donor-card-subheading text-size-small meta">{isIndividual ? "I" : "We"} donated because…</span>
          <p className="text-size-medium">
            First United provides <mark>{donor.reason_provides}</mark> to people who <mark>{donor.reason_who}</mark>.
          </p>
        </div>
      )}
    </dialog>
  );
};

export { DonorCardDialog };
