// https://codepen.io/tksiiii/pen/VOEKLQ

import React, { useState, useRef, useEffect } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { BasicFIreWorks, RichFIreWorks } from "./FireworksClasses";
import * as THREE from "three";

let isAutoLaunch = true;

const gravity = new THREE.Vector3(0, -0.005, 0);
const fireworksInstances = [];

const Fireworks = () => {
	const state = useThree();
	const scene = state.scene;

	const launchFireWorks = () => {
		if (fireworksInstances.length > 10) return;
		const fw = Math.random() > 8 ? new BasicFIreWorks() : new RichFIreWorks();
		fireworksInstances.push(fw);
		scene.add(fw.meshGroup);
	};

	const autoLaunch = () => {
		if (!isAutoLaunch) return;
		if (Math.random() > 0.3) launchFireWorks();
	};

	const render = () => {
		const exploadedIndexList = [];
		
		for (let i = fireworksInstances.length - 1; i >= 0; i--) {
			const instance = fireworksInstances[i];
			instance.update(gravity);
			if (instance.isExplode) exploadedIndexList.push(i);
		}

		for (let i = 0, l = exploadedIndexList.length; i < l; i++) {
			const index = exploadedIndexList[i];
			const instance = fireworksInstances[index];
			if (!instance) return;

			/*
				Be careful because js heap size will continue to increase unless you do the following:
				- Remove unuse mesh from scene 
				- Execute dispose method of Geometres and Materials in the Mesh
			*/
			instance.meshGroup.remove(instance.seed.mesh);
			instance.seed.disposeAll();
			if (instance.life <= 0) {
				scene.remove(instance.meshGroup);
				if (instance.tailMeshGroup) {
					instance.tails.forEach(v => {
						v.disposeAll();
					});
				}
				instance.flower.disposeAll();
				fireworksInstances.splice(index, 1);
			}
		}

		// renderer.render(scene, camera);

		// requestAnimationFrame(render);
	};

	// const box = new THREE.Mesh(new THREE.BoxGeometry(2, 2, 2), new THREE.MeshPhongMaterial());
	// scene.add(box);

	useFrame(render);

	useEffect(() => {
		const timer = setInterval(autoLaunch, 400);
		return () => {
			clearInterval(timer);
			for (let i = fireworksInstances.length - 1; i >= 0; i--) {
				const instance = fireworksInstances[i];
				scene.remove(instance.meshGroup);
				instance.seed.disposeAll();
				if (instance.tailMeshGroup) {
					instance.tails.forEach(v => {
						v.disposeAll();
					});
				}
				instance?.flower?.disposeAll();
				fireworksInstances.splice(i, 1);
			}
		}
	}, []);

	return (
		<></>
	)
};

export { Fireworks };