import React, { useState, useEffect, useRef } from "react";
import * as stripeJs from "@stripe/stripe-js";

import {
  Elements,
  AddressElement,
  CardElement,
  PaymentElement,
  PaymentRequestButtonElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import { Loading } from "../Loading";
import Donation from "../types/Donation";
import { FormCompletionLoader } from "../FormCompletionLoader";

const Payment = ({
  donation,
  setDonation,
  onPaymentMethod,
  onPaymentSubmit,
  submitting,
  validated,
  message,
}: {
  donation: Donation;
  setDonation: (donation: Donation) => void;
  onPaymentMethod: (e: stripeJs.PaymentRequestPaymentMethodEvent, stripe: stripeJs.Stripe) => void;
  onPaymentSubmit: (e: React.FormEvent<HTMLFormElement>, stripe: stripeJs.Stripe, elements: stripeJs.StripeElements) => void;
  submitting: boolean;
  validated: boolean;
  message: string | undefined;
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [paymentRequest, setPaymentRequest] = useState<stripeJs.PaymentRequest | null>(null);
  const [canPayWithPaymentRequest, setCanPayWithPaymentRequest] = useState<boolean>(false);
  const [showCredit, setShowCredit] = useState<boolean>(false);
  const [isPaymentComplete, setIsPaymentComplete] = useState<boolean>(false);
  const [isAddressComplete, setIsAddressComplete] = useState<boolean>(false);

  const creditForm = useRef<HTMLDivElement>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (canPayWithPaymentRequest && showCredit && creditForm.current) {
      creditForm.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [showCredit, canPayWithPaymentRequest]);

  useEffect(() => {
    if (paymentRequest && donation.amount) {
      paymentRequest.update({
        total: {
          label: "First United",
          amount: Math.round(donation.amount),
        },
      });
    }
  }, [donation.amount]);

  const finalize = (e: stripeJs.PaymentRequestPaymentMethodEvent) => {
    if (stripe) {
      onPaymentMethod(e, stripe);
    } else {
      console.log("Stripe was not loaded in the payment finalize function.");
    }
  };

  // Lots of help from here: https://stackoverflow.com/questions/60865022/stripe-payment-request-button-with-subscriptions
  useEffect(() => {
    if (stripe && !paymentRequest && donation.amount) {
      const pr = stripe.paymentRequest({
        country: "CA",
        currency: "cad",
        total: {
          label: "First United",
          amount: Math.round(donation.amount),
        },
        disableWallets: ["link"],
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true,
        requestShipping: true,
        shippingOptions: [
          {
            id: "tax-receipt",
            label: "Tax Receipt",
            detail: "Sent by email",
            amount: 0,
          },
        ],
      });
      setPaymentRequest(pr);
    }
  }, [stripe]);

  useEffect(() => {
    if (paymentRequest) {
      // Check the availability of the Payment Request API.
      paymentRequest.canMakePayment().then((result: stripeJs.CanMakePaymentResult | null) => {
        setIsLoading(false);
        if (result) {
          setCanPayWithPaymentRequest(true);
          paymentRequest.on("paymentmethod", finalize);
        }
      });
    }
    return () => {
      if (paymentRequest) {
        // @ts-ignore
        paymentRequest.removeAllListeners(); // Not officially supported, but helpful in the meantime.
        // paymentRequest.off('paymentmethod', finalize) // Fails if called successively
      }
    };
  }, [paymentRequest, donation]);

  if (!stripe || !elements) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const readyToSubmit = validated && isPaymentComplete && isAddressComplete && donation.email && donation.email.includes("@");

  return (
    <form onSubmit={(e) => !submitting && readyToSubmit && onPaymentSubmit(e, stripe, elements)}>
      {canPayWithPaymentRequest && paymentRequest && (
        <>
          <h2>Pay by</h2>
          {message && <div className="payment-error">{message}</div>}
          <div className={`payment-methods ${!donation.payment_name ? "is-disabled" : ""}`}>
            <PaymentRequestButtonElement
              // key={Math.random()}
              options={{
                paymentRequest: paymentRequest,
                style: {
                  paymentRequestButton: {
                    // type: 'donate',
                  },
                },
              }}
            />
            <div className="payment-methods-or text-size-medium">- or -</div>
            <button
              type="button"
              className={"button" + (showCredit ? " button--outline" : "")}
              onClick={(e) => setShowCredit(!showCredit)}
              disabled={!donation.payment_name}
            >
              Credit Card
            </button>
          </div>
        </>
      )}

      {!canPayWithPaymentRequest && <h2>Pay by Credit Card</h2>}

      {(!canPayWithPaymentRequest || showCredit) && (
        <>
          <div ref={creditForm}>
            <p className="text-size-small bold">All fields are required.</p>
            <label htmlFor="field-email">Email</label>
            <div>
              <input
                required
                type="email"
                id="field-email"
                name="name"
                className=""
                placeholder="email@example.com"
                value={donation.email || ""}
                onChange={(e) => setDonation({ ...donation, email: e.target.value })}
              />
            </div>
          </div>

          <AddressElement
            className="mb-20"
            onChange={(event) => {
              setIsAddressComplete(event.complete);
            }}
            options={{
              mode: "billing",
              // allowedCountries: ['CA'],
              // display: {
              //   name: "split",
              // },
              fields: {
                phone: "never", // Make our own field, Stripe's defaults to the US and can't be changed :S
              },
              defaultValues: {
                name: donation.payment_name, // probably need more work
              },
            }}
          />

          <PaymentElement
            className="mb-20"
            id="payment-element"
            onChange={(event: stripeJs.StripePaymentElementChangeEvent) => {
              setIsPaymentComplete(event.complete);
            }}
            options={{
              layout: "tabs",
              // billingDetails: 'never',
              wallets: {
                applePay: "never",
                googlePay: "never",
              },
            }}
          />

          <div aria-hidden="true" className="donate-secure big-secure">
            <div>Your payment details are secure and not stored by First United.</div>
          </div>

          {message && <div className="text-size-medium payment-error">{message}</div>}
          <button className="button" type="submit" disabled={submitting || !readyToSubmit}>
            Complete Payment
          </button>
          {submitting && <FormCompletionLoader />}
          {/* <FormCompletionLoader /> */}
        </>
      )}
    </form>
  );
};

export { Payment };
