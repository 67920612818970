import React from "react";
import { easing } from "maath";

const ProgressBar = ({ moneyRaised }: { moneyRaised: number }) => {
  const GOAL = 100000; //100,000
  const progress = (moneyRaised / GOAL) * 100;
  const isStartOfCampaign = progress < 20;

  return (
    <div className="progress-bar">
      <div className="progress-bar-meter">
        <div className="progress-bar-mask" style={{ width: `${100 - progress}%` }}></div>
        <span
          className={`progress-bar-raised text-size-small ${isStartOfCampaign ? "progress-bar-raised--start" : ""}`}
          style={{ left: `${progress}%` }}
        >
          ${(moneyRaised / 1000).toFixed(1)}k
        </span>
      </div>
      <span className="progress-bar-total text-size-small">${GOAL / 1000}k</span>
    </div>
  );
};

export { ProgressBar };
