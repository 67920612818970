import React, { useState, useEffect, useRef } from "react";

const StepCounter = ({ activeStep, goToStep }: { activeStep: number; goToStep: (step: number) => void }) => {
  const numSteps = 3;
  const counterStep = activeStep;

  if (counterStep < 1 || counterStep > numSteps) {
    return null;
  }

  const handleClick = (i: number) => {
    // if the step is already active, do nothing
    if (i + 1 === counterStep || i + 1 > counterStep) {
      return;
    }
    goToStep(i + 1);
  };

  return (
    <div className="step-counter">
      {Array.from({ length: numSteps }, (step, i) => (
        <button onClick={() => handleClick(i)} key={i} className={`step ${i + 1 === counterStep ? "step--active" : ""}`}>
          {i + 1}
        </button>
      ))}
    </div>
  );
};

export { StepCounter };